import React, { useState, useRef } from "react";
import {
  TextField,
  Input,
  Button,
  Box,
  Typography,
  Grid,
  Container,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
} from "@mui/material";
import { importResiduos } from "../service/apiResiduos";
import * as XLSX from "xlsx";

const ImportarResiduos = ({ openDialog, onClose }) => {
  const [status, setStatus] = useState("");
  const [isUploading, setIsUploading] = useState(false);
  const fileInputRef = useRef(null);
  const [habilitado, setHablitado] = useState(true);
  const [selectedFile, setSelectedFile] = useState(null);

  const columnasResiduos = [
    "ler",
    "nombre",
    "codigo_interno",
    "iva",
    "maestro",
    "fianzas",
  ];
  const handleFileChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      const allowedExtensions = [".xlsx", ".xls"];
      const fileExtension = file.name.split(".").pop().toLowerCase();

      if (!allowedExtensions.includes(`.${fileExtension}`)) {
        setHablitado(true);
        setStatus("Error: El archivo debe ser un Excel (.xlsx o .xls).");
        event.target.value = "";
        return;
      }
      const reader = new FileReader();
      reader.onload = (e) => {
        const data = new Uint8Array(e.target.result);
        const workbook = XLSX.read(data, { type: "array" });
        const sheetName = workbook.SheetNames[0];
        const sheet = workbook.Sheets[sheetName];
        const jsonData = XLSX.utils.sheet_to_json(sheet, { header: 1 });

        const encabezados = jsonData[0];

        if (encabezados.length !== columnasResiduos.length) {
          setStatus(
            "Error: Las columnas del archivo no coinciden con las requeridas."
          );
          setSelectedFile(null);
          return;
        }

        const columnasValidas = columnasResiduos.every((col) =>
          encabezados.includes(col)
        );
        if (!columnasValidas) {
          setStatus(
            "Error: Las columnas del archivo no coinciden con las requeridas."
          );
          setSelectedFile(null);
          return;
        }

        setSelectedFile(file);
        setStatus("");
      };
      reader.readAsArrayBuffer(file);
    } else {
      setStatus("No se seleccionó ningún archivo");
    }
  };
  const getStatusColor = (status) => {
    switch (status) {
      case "No se seleccionó ningún archivo":
        return "warning.main";
      case "Subiendo archivo...":
        return "info.main";
      case "Error: El archivo debe ser un Excel (.xlsx o .xls).":
        return "error.main";
      case "Error: Las columnas del archivo no coinciden con las requeridas.":
        return "error.main";
      default:
        return "primary";
    }
  };
  const saveFile = async () => {
    if (!selectedFile) {
      setStatus(
        "No se seleccionó ningún archivo o no tiene el formato correcto"
      );
      return;
    }
    const formData = new FormData();
    formData.append("archivo", selectedFile);
    try {
      const response = await importResiduos(formData);
      setStatus(
        `éxito: ${response.message}, TotalResiduos: ${response.totalResiduos},TotalPrecios: ${response.totalPrecios}`
      );
    } catch (error) {
      setStatus("Error al subir el archivo, valide la estructura. ");
      console.error(error);
    }
  };
  const handleClose = () => {
    setSelectedFile(null);
    setStatus("");
    onClose();
  };

  return (
    <>
      <Dialog open={openDialog} onClose={onClose} maxWidth="sm" fullWidth>
        <DialogTitle>Importar Residuos.</DialogTitle>
        <DialogContent>
          <input
            type="file"
            ref={fileInputRef}
            accept=".xlsx, .xls"
            onChange={handleFileChange}
          />
          <Typography color={getStatusColor(status)}>{status}</Typography>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="secondary">
            Cancelar
          </Button>
          <Button disabled={habilitado} onClick={saveFile} color="primary">
            Importar
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default ImportarResiduos;
