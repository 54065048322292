import React, { useState, useEffect } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Button,
  FormControl,
  InputAdornment,
  Paper,
  Autocomplete,
} from "@mui/material";
import { getResiduos, getResiduoByIdAndAlmacen } from "../service/apiResiduos";
import Modal from "../modal/modal";
import DeleteIcon from "@mui/icons-material/Delete";

function ResiduosTable({ almacenId, rows, setRows, onRowsUpdate }) {
  const [residuosOptions, setResiduosOptions] = useState([]);
  const [editableRow, setEditableRow] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const [modalMessage, setModalMessage] = useState("");

  useEffect(() => {
    const fetchResiduosForAlmacen = async () => {
      if (!almacenId) {
        return;
      }

      const updatedRows = [...rows];
      for (let index = 0; index < updatedRows.length; index++) {
        const currentRow = updatedRows[index];

        if (!currentRow.articulo) {
          updatedRows[index] = {
            ...currentRow,
            articulo: "",
            descripcion: "",
            precio: 0,
            impuesto: 0,
            cantidad: 0,
            subtotal: 0,
            total: 0,
          };
        } else {
          try {
            const [residuoData] = await getResiduoByIdAndAlmacen(
              almacenId.id,
              currentRow.articulo
            );

            if (residuoData) {
              updatedRows[index] = {
                ...currentRow,
                precio: residuoData.precio || 0,
                impuesto: residuoData.res_iva || 0,
                subtotal: currentRow.cantidad * residuoData.precio,
                total: currentRow.cantidad * residuoData.precio,
              };
            } else {
              updatedRows[index] = {
                ...updatedRows[index],
                articulo: "",
                descripcion: "",
                precio: 0,
                impuesto: 0,
                cantidad: 0,
                subtotal: 0,
                total: 0,
              };
              setShowModal(true);
              setModalMessage(
                `No se encontró precio para el artículo ${currentRow.articulo} en el almacén seleccionado.`
              );
            }
          } catch (error) {
            console.error(
              `Error al obtener residuo ${currentRow.articulo}:`,
              error
            );
            alert("Ocurrió un error al actualizar los datos de los residuos.");
          }
        }
      }
      setRows(updatedRows); // Actualiza el estado en CrearAlbaran
      onRowsUpdate(updatedRows); // Notifica a CrearAlbaran sobre la actualización
    };
    fetchResiduosForAlmacen();
  }, [almacenId]);

  const handleInputChange = async (index, field, value) => {
    const updatedRows = [...rows];
    updatedRows[index][field] = value;

    if (field === "articulo" && value) {
      if (!almacenId) {
        setShowModal(true);
        setModalMessage(`Debe Seleccionar un almacen para visualizar residuos`);
        updatedRows[index] = {
          articulo: "",
          descripcion: "",
          precio: 0,
          impuesto: 0,
          cantidad: 0,
          subtotal: 0,
          total: 0,
        };
        return;
      } else {
        const [residuoData] = await getResiduoByIdAndAlmacen(
          almacenId.id,
          value
        );
        if (value.length > 1) {
          if (residuoData === undefined) {
            setShowModal(true);
            setModalMessage(`No hay artículo con el id: ${value}`);
            updatedRows[index] = {
              ...updatedRows[index],
              articulo: "",
              descripcion: "",
              precio: 0,
              impuesto: 0,
              cantidad: 0,
              subtotal: 0,
              total: 0,
            };
          }
        }

        if (residuoData) {
          updatedRows[index] = {
            ...updatedRows[index],
            articulo: residuoData.res_id || value,
            descripcion: residuoData.res_nombre,
            precio: residuoData.precio || 0,
            impuesto: residuoData.res_iva || 0,
            cantidad: 0,
            subtotal: 0,
            total: 0,
          };
        } else if (value.length > 2) {
          updatedRows[index] = {
            ...updatedRows[index],
            articulo: "",
            descripcion: "",
            precio: 0,
            impuesto: 0,
            cantidad: 0,
            subtotal: 0,
            total: 0,
          };
          setShowModal(true);
          setModalMessage("No hay artículo con el id", value);
        }
      }
    } else if (field === "articulo" && !value) {
      updatedRows[index] = {
        ...updatedRows[index],
        articulo: "",
        descripcion: "",
        precio: 0,
        impuesto: 0,
        cantidad: 0,
        subtotal: 0,
        total: 0,
      };
    } else if (field === "descripcion" && value) {
      if (!almacenId) {
        setShowModal(true);
        setModalMessage(
          `Debe seleccionar un almacén para visualizar residuos.`
        );
        return;
      }

      const residuoData = await getResiduos();
      const filteredResiduos = residuoData.filter((residuo) =>
        residuo.res_nombre.toLowerCase().includes(value.toLowerCase())
      );
      setResiduosOptions(filteredResiduos);
      const IDdescripResiduos = filteredResiduos[0]?.res_id || null;

      if (IDdescripResiduos) {
        const residuoDataXPrecio = await getResiduoByIdAndAlmacen(
          almacenId.id,
          IDdescripResiduos
        );

        if (filteredResiduos.length === 1 && residuoDataXPrecio.length === 1) {
          const selectedResiduo = residuoDataXPrecio[0];
          if (selectedResiduo) {
            updatedRows[index] = {
              articulo: selectedResiduo.res_id,
              descripcion: selectedResiduo.res_nombre,
              precio: selectedResiduo.precio || 0,
              impuesto: selectedResiduo.res_iva || 0,
              cantidad: 0,
              subtotal: 0,
              total: 0,
            };
          }
        }
      }
    } else if (field === "descripcion" && !value) {
      updatedRows[index] = {
        ...updatedRows[index],
        articulo: "",
        descripcion: "",
        precio: 0,
        impuesto: 0,
        cantidad: 0,
        subtotal: 0,
        total: 0,
      };
    } else if (field === "cantidad" && value) {
      const regex = /^\d*(\.\d*)?$/;
      if (!regex.test(value)) {
        setShowModal(true);
        setModalMessage(
          `Solo puede agregar números y para agregar decimales, agregue un punto (.)`
        );
        updatedRows[index].cantidad = "";
        return;
      } else {
        const valorCantidad = parseFloat(value);
        if (updatedRows) {
          const impuestoIVADesc =
            valorCantidad *
            parseFloat(updatedRows[index].precio) *
            (parseFloat(updatedRows[index].impuesto) / 100);
          const subtotal =
            valorCantidad * parseFloat(updatedRows[index].precio);
          const total =
            valorCantidad * parseFloat(updatedRows[index].precio) +
            impuestoIVADesc;
          // Actualización de totales
          updatedRows[index].subtotal = subtotal;
          updatedRows[index].total = total;
        } else {
          updatedRows[index].cantidad = "";
        }
      }
    } else if (field === "cantidad" && !value) {
      updatedRows[index] = {
        ...updatedRows[index],
        cantidad: 0,
        total: 0,
      };
    } else if (field === "precio" && value) {
      const regex = /^\d*(\.\d*)?$/;
      if (!regex.test(value)) {
        setShowModal(true);
        setModalMessage(
          `Solo puede agregar números y para agregar decimales, agregue un punto (.)`
        );
        updatedRows[index].precio = "";
        return;
      } else {
        const valueParse = parseFloat(value);
        if (updatedRows) {
          const impuestoIVADesc =
            valueParse *
            parseFloat(updatedRows[index].cantidad) *
            (parseFloat(updatedRows[index].impuesto) / 100);
          const subtotal = valueParse * parseFloat(updatedRows[index].cantidad);
          const total =
            valueParse * parseFloat(updatedRows[index].cantidad) +
            impuestoIVADesc;

          // Actualización de totales
          updatedRows[index].precio = value;
          updatedRows[index].subtotal = subtotal;
          updatedRows[index].total = total;
        }
      }
    } else if (field === "precio" && !value) {
      updatedRows[index] = {
        ...updatedRows[index],
        precio: 0,
        total: 0,
      };
    } else {
      updatedRows[index].total = calculateTotal(updatedRows[index]);
    }
    setRows(updatedRows); // Actualiza el estado en CrearAlbaran
    onRowsUpdate(updatedRows); // Notifica a CrearAlbaran sobre la actualización
  };
  const calculateTotal = (row) => {
    const subtotal = row.cantidad * row.precio;
    const discountAmount = (row.descuento / 100) * subtotal;
    return subtotal - discountAmount;
  };
  const handleAddRow = () => {
    const newRow = {
      articulo: "",
      cantidad: 0,
      descripcion: "",
      impuesto: 0,
      precio: 0,
      subtotal: 0,
      total: 0,
    };
    const updatedRows = [...rows, newRow];
    setRows(updatedRows); // Actualiza el estado en CrearAlbaran
    onRowsUpdate(updatedRows); // Notifica a CrearAlbaran sobre la actualización
  };
  const handleDeleteRow = (index) => {
    // Usar la función de actualización del estado para garantizar que se actualiza correctamente
    setRows((prevRows) => {
      const newRows = prevRows.filter((_, i) => i !== index);
      onRowsUpdate(newRows); // Llamar a la función con el nuevo estado
      return newRows;
    });
  };
  const handleDoubleClick = (index) => {
    if (editableRow !== index) {
      setEditableRow(index); // Establece la fila que será editable
      setShowModal(true);
      setModalMessage(
        `El campo de la fila ${index + 1} se habilitará para ser editado.`
      );
    } else {
      setEditableRow(null); // Desactiva la edición si vuelves a hacer doble clic
    }
  };
  const calculateTotalAlbaran = () => {
    return rows.reduce((total, row) => total + row.total, 0).toFixed(2);
  };
  return (
    <div style={{ padding: "10px" }}>
      <h3 className="form-subtitle">Agregar Residuos</h3>
      <TableContainer
        component={Paper}
        style={{ overflowX: "auto", backgroundColor: "#f5f5f5" }}
      >
        <Table style={{ minWidth: "700px" }}>
          <TableHead className="tableHead-residuos">
            <TableRow>
              <TableCell>Linea</TableCell>
              <TableCell>Artículo</TableCell>
              <TableCell>Descripción</TableCell>
              <TableCell>Cantidad</TableCell>
              <TableCell>Precio</TableCell>
              <TableCell>Impuesto</TableCell>
              <TableCell>Subtotal</TableCell>
              <TableCell>Acciones</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {rows.map((row, index) => (
              <TableRow key={index}>
                <TableCell key={`linea${row.id}`} width={"5%"}>
                  {index + 1}
                </TableCell>
                <TableCell key={`articulo_${row.id}`} width={"15%"}>
                  <FormControl fullWidth>
                    <TextField
                      size="small"
                      value={row.articulo}
                      onChange={(e) =>
                        handleInputChange(index, "articulo", e.target.value)
                      }
                      placeholder="Artículo (ID)"
                      variant="outlined"
                    />
                  </FormControl>
                </TableCell>
                <TableCell key={`descripcion_${row.id}`} width={"25%"}>
                  <FormControl fullWidth>
                    <Autocomplete
                      noOptionsText="No se encontraron residuos"
                      size="small"
                      value={{
                        res_nombre: rows[index]?.descripcion || "",
                      }}
                      options={residuosOptions}
                      getOptionLabel={(option) => option.res_nombre || ""}
                      onInputChange={(event, newValue) =>
                        handleInputChange(index, "descripcion", newValue)
                      }
                      onChange={(event, value) => {
                        if (value) {
                          handleInputChange(
                            index,
                            "descripcion",
                            value.res_nombre
                          );
                        }
                      }}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          onChange={(e) =>
                            handleInputChange(
                              index,
                              "descripcion",
                              e.target.value
                            )
                          }
                          placeholder="Descripción"
                          variant="outlined"
                        />
                      )}
                    />
                  </FormControl>
                </TableCell>
                <TableCell key={`cantidad_${row.id}`} width={"10%"}>
                  <FormControl fullWidth>
                    <TextField
                      size="small"
                      type="text"
                      value={row.cantidad || ""}
                      onChange={(e) =>
                        handleInputChange(index, "cantidad", e.target.value)
                      }
                      placeholder="Cantidad"
                      variant="outlined"
                    />
                  </FormControl>
                </TableCell>
                <TableCell key={`precio_${row.id}`} width={"10%"}>
                  <FormControl fullWidth>
                    <TextField
                      size="small"
                      title="Doble clic para editar el precio"
                      type="text"
                      value={row.precio || ""}
                      onChange={(e) =>
                        handleInputChange(index, "precio", e.target.value)
                      }
                      placeholder="Precio"
                      variant="outlined"
                      onDoubleClick={() => handleDoubleClick(index)}
                      InputProps={{
                        readOnly: editableRow !== index,
                      }}
                    />
                    {showModal && (
                      <Modal
                        message={modalMessage}
                        onClose={() => setShowModal(false)}
                      />
                    )}
                  </FormControl>
                </TableCell>
                <TableCell key={`impuesto_${row.id}`} width={"10%"}>
                  <FormControl fullWidth>
                    <TextField
                      size="small"
                      type="text"
                      value={row.impuesto || 0}
                      onChange={(e) =>
                        handleInputChange(
                          index,
                          "impuesto",
                          parseFloat(e.target.value)
                        )
                      }
                      placeholder="Impuesto"
                      variant="outlined"
                      disabled={true}
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">%</InputAdornment>
                        ),
                      }}
                    />
                  </FormControl>
                </TableCell>
                <TableCell key={`subtotal_${row.id}`} width={"10%"}>
                  {row.total.toFixed(2)}
                </TableCell>
                <TableCell width={"5%"}>
                  <Button
                    key={`eliminar_${row.id}`}
                    size="small"
                    color="error"
                    variant="outlined"
                    onClick={() => handleDeleteRow(index)}
                  >
                    <DeleteIcon />
                  </Button>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
        <Button
          key="agregar"
          style={{ background: "#1abd9c", margin: "10px" }}
          onClick={handleAddRow}
          variant="contained"
        >
          Agregar Fila
        </Button>
        <TextField
          size="small"
          key="inputTotal"
          label="Total Final"
          value={calculateTotalAlbaran()}
          InputProps={{
            readOnly: true,
          }}
          variant="outlined"
          style={{
            width: "100px",
            position: "relative",
            left: "70%",
            margin: "10px",
          }}
        />
      </TableContainer>
    </div>
  );
}

export { ResiduosTable };
