import React, { useState, useRef } from "react";
import {
  Button,
  Typography,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
} from "@mui/material";
import { importarProveedores } from "../service/apiProveedores";
import * as XLSX from "xlsx";

const ImportarProveedores = ({ openDialog, onClose }) => {
  const [status, setStatus] = useState("");
  const [selectedFile, setSelectedFile] = useState(null);
  const [inputKey, setInputKey] = useState(Date.now());
  const fileInputRef = useRef(null);

  const columnasProveedor = [
    "nombre",
    "direccion",
    "codigo_postal",
    "poblacion",
    "provincia",
    "telefono",
    "nif",
    "email",
    "cta_bancaria",
    "tarjeta_ecopay",
    "matricula",
  ];

  const handleFileChange = (event) => {
    const file = event.target.files[0];

    if (file) {
      const allowedExtensions = [".xlsx", ".xls"];
      const fileExtension = file.name.split(".").pop().toLowerCase();

      if (!allowedExtensions.includes(`.${fileExtension}`)) {
        setStatus("Error: El archivo debe ser un Excel (.xlsx o .xls).");
        event.target.value = "";
        return;
      }

      const reader = new FileReader();
      reader.onload = (e) => {
        const data = new Uint8Array(e.target.result);
        const workbook = XLSX.read(data, { type: "array" });
        const sheetName = workbook.SheetNames[0];
        const sheet = workbook.Sheets[sheetName];
        const jsonData = XLSX.utils.sheet_to_json(sheet, { header: 1 });

        const encabezados = jsonData[0];
        if (encabezados.length !== columnasProveedor.length) {
          setStatus(
            "Error: Las columnas del archivo no coinciden con las requeridas."
          );
          setSelectedFile(null);
          return;
        }

        const columnasValidas = columnasProveedor.every((col) =>
          encabezados.includes(col)
        );
        if (!columnasValidas) {
          setStatus(
            "Error: Las columnas del archivo no coinciden con las requeridas."
          );
          setSelectedFile(null);
          return;
        }

        setSelectedFile(file);
        setStatus("");
      };
      reader.readAsArrayBuffer(file);
    } else {
      setStatus("No se seleccionó ningún archivo");
    }
  };
  const getStatusColor = (status) => {
    switch (status) {
      case "No se seleccionó ningún archivo":
        return "warning";
      case "Subiendo archivo...":
        return "info";
      case "Error: El archivo debe ser un Excel (.xlsx o .xls).":
        return "error";
      case "Error: Las columnas del archivo no coinciden con las requeridas.":
        return "error.main";
      default:
        return "primary";
    }
  };
  const saveFile = async () => {
    if (!selectedFile) {
      setStatus(
        "No se seleccionó ningún archivo o no tiene el formato correcto"
      );
      return;
    }

    const formData = new FormData();
    formData.append("archivo", selectedFile);

    try {
      const response = await importarProveedores(formData);
      setStatus(
        `Éxito: ${response.message}, Total Proveedores: ${response.total}`
      );

      setSelectedFile(null);
      setInputKey(Date.now());
    } catch (error) {
      setStatus("Error al subir el archivo, valide la estructura.");
      console.error(error);
    }
  };
  const handleClose = () => {
    setSelectedFile(null);
    setStatus("");
    setInputKey(Date.now());
    onClose();
  };
  return (
    <Dialog open={openDialog} onClose={onClose} maxWidth="sm" fullWidth>
      <DialogTitle>Importar Almacenes</DialogTitle>
      <DialogContent>
        <input
          key={inputKey}
          ref={fileInputRef}
          type="file"
          accept=".xlsx, .xls"
          onChange={handleFileChange}
        />
        <Typography color={getStatusColor(status)}>{status}</Typography>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} color="secondary">
          Cancelar
        </Button>
        <Button disabled={!selectedFile} onClick={saveFile} color="primary">
          Importar
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ImportarProveedores;
