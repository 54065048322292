import React, { useState, useEffect } from "react";
import {
  Select,
  CircularProgress,
  FormControl,
  InputLabel,
  MenuItem,
} from "@mui/material";
import { getSeries, getSeriesByIdTipo } from "../service/apiSeries";

const SeriesSelect = ({ onSerieChange, ValorConsecutivo }) => {
  const [series, setSeries] = useState([]);
  const [selectedSerie, setSelectedSerie] = useState("");
  const [loading, setLoading] = useState(false);

  // Fetch de todas las series al cargar el componente
  const fetchSeries = async () => {
    try {
      setLoading(true);
      const response = await getSeries();
      setSeries(response);

      if (response.length > 0) {
        setSelectedSerie(response[0].ser_id); // Seleccionar la primera serie por defecto
        fetchConsecutivos(response[0].ser_id);
        if (typeof onSerieChange === "function") {
          onSerieChange(response[0].ser_id);
        }
      }

      setLoading(false);
    } catch (error) {
      console.error("Error al obtener los tipos de serie", error);
      setLoading(false);
    }
  };

  // Fetch de los consecutivos por tipo de serie
  const fetchConsecutivos = async (idtipo) => {
    try {
      if (!idtipo) return;

      setLoading(true);
      const response = await getSeriesByIdTipo(idtipo);
      setLoading(false);
      // Pasa el consecutivo al padre
      if (typeof ValorConsecutivo === "function") {
        ValorConsecutivo(response[0]?.ultimo_consecutivo || null);
      } else {
        console.warn("Valor Consecutivo no es una función válida.");
      }
    } catch (error) {
      console.error("Error al obtener los consecutivos", error);
      setLoading(false);
    }
  };

  // Manejo del cambio en el select
  const handleChange = (event) => {
    const nuevaSerie = event.target.value;
    setSelectedSerie(nuevaSerie);
    if (typeof onSerieChange === "function") {
      onSerieChange(nuevaSerie);
    }
    fetchConsecutivos(nuevaSerie);
  };

  // Fetch inicial de series y consecutivos para el valor inicial
  useEffect(() => {
    fetchSeries();
    fetchConsecutivos(selectedSerie);
  }, []);

  return (
    <FormControl size="small" fullWidth>
      <InputLabel id="serie-label">Serie Albarán</InputLabel>
      {loading ? (
        <CircularProgress />
      ) : (
        <Select
          label="Serie Albarán"
          labelId="serie-label"
          value={selectedSerie}
          onChange={handleChange}
        >
          {series.length === 0 ? (
            <MenuItem disabled> No se encontraron series </MenuItem>
          ) : (
            series.map((serie) => (
              <MenuItem key={serie.ser_id} value={serie.ser_id}>
                {serie.ser_tipo}
              </MenuItem>
            ))
          )}
        </Select>
      )}
    </FormControl>
  );
};

export { SeriesSelect };
