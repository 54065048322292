import React, { useState } from "react";
import {
  Box,
  TextField,
  Button,
  Snackbar,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "@mui/material";
import { addAlmacen, getAlmacenes } from "../service/apiAlmacenes";

const CrearAlmacen = ({
  openDialog,
  onClose,
  setSnackbarMessage,
  setOpenSnackbar,
  setAlmacenes,
}) => {
  const [formData, setFormData] = useState({
    alm_nombre: "",
    alm_tipo: "",
    alm_porcentaje: 0,
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    if (name == "alm_porcentaje") {
      const regex = /^[0-9]*\.?[0-9]*$/;
      if (!regex.test(value)) {
        setSnackbarMessage("El campo de porcentaje debe tener solo numeros");
        setOpenSnackbar(true);
        return;
      }
    }
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (formData.alm_nombre.trim() === "") {
      setSnackbarMessage("El nombre del almacén no puede estar vacío");
      setOpenSnackbar(true);
      return;
    }

    // Llamada a la API para agregar el almacén
    try {
      const response = await addAlmacen(formData); // Asegúrate de que esta función esté bien implementada
      if (response) {
        setSnackbarMessage(`Almacén "${formData.alm_nombre}" creado con éxito`);
        setOpenSnackbar(true);
        //setFormData({ alm_nombre: "" });
        const updatedAlmacenes = await getAlmacenes(); // Obtener los almacenes actualizados
        setAlmacenes(updatedAlmacenes);
        onClose(); // Cerrar el modal después de la creación exitosa
      } else {
        setSnackbarMessage("Hubo un error al crear el almacén");
        setOpenSnackbar(true);
      }
    } catch (error) {
      console.error("Error al crear el almacén:", error);
      setSnackbarMessage("Hubo un error al crear el almacén");
      setOpenSnackbar(true);
    }
  };

  return (
    <Dialog open={openDialog} onClose={onClose} maxWidth="sm" fullWidth>
      <DialogTitle>Crear Almacén</DialogTitle>
      <DialogContent>
        <Box>
          <form onSubmit={handleSubmit}>
            {Object.keys(formData).map((key) => (
              <TextField
                key={key}
                label={key.replace("alm_", "").replace("_", " ")}
                margin="dense"
                variant="outlined"
                fullWidth
                name={key}
                value={formData[key]}
                onChange={handleChange}
              />
            ))}

            <Button
              type="submit"
              variant="contained"
              color="primary"
              fullWidth
              sx={{ marginTop: "10px" }}
            >
              Crear Almacén
            </Button>
          </form>
        </Box>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="secondary">
          Cancelar
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default CrearAlmacen;
