import React, { useState, useEffect } from "react";
import {
  Select,
  CircularProgress,
  Autocomplete,
  FormControl,
  InputLabel,
  MenuItem,
} from "@mui/material";
import { getEstadoAlbaran } from "../service/apiEstadosAlbaran";

const EstadosSelect = ({ onEstadoChange, codCliente }) => {
  const [estados, setEstados] = useState([]);
  const [selectedEstado, setSelectedEstado] = useState("");
  const [loading, setLoading] = useState(false);

  const fetchEstadosAlbaran = async () => {
    try {
      setLoading(true);
      const response = await getEstadoAlbaran();
      setEstados(response);

      if (response.length > 0) {
        setSelectedEstado(response[3].est_alb_id);
      }

      setLoading(false);
    } catch (error) {
      console.error("Error al obtener los estados de Albaran ", error);
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchEstadosAlbaran();
  }, []);

  const handleChange = (event) => {
    setSelectedEstado(event.target.value);
    const [estadoSeleccionado] = estados.filter(
      (estado) => estado.est_alb_id === event.target.value
    );
    const nombreEstado = estadoSeleccionado.est_alb_nombre;
    if (typeof onEstadoChange === "function") {
      codCliente(event.target.value);
      onEstadoChange(nombreEstado);
    }
  };

  return (
    <FormControl size="small" fullWidth>
      <InputLabel id="estado-label">Selecciona Estado Albarán </InputLabel>
      {loading ? (
        <CircularProgress />
      ) : (
        <Select
          fullWidth
          labelId="estado-label"
          value={selectedEstado}
          label="Selecciona Estado Alabarán"
          onChange={handleChange}
          MenuProps={{
            PaperProps: {
              style: {
                maxHeight: 200,
                overflowY: "auto",
              },
            },
          }}
        >
          {estados.length === 0 ? (
            <MenuItem disabled> No se encontraron estados</MenuItem>
          ) : (
            estados.map((estado) => (
              <MenuItem key={estado.est_alb_id} value={estado.est_alb_id}>
                {estado.est_alb_nombre}
              </MenuItem>
            ))
          )}
        </Select>
      )}
    </FormControl>
  );
};

export { EstadosSelect };
