import React, { useState } from 'react';
import { Box, TextField, Button, Typography, Snackbar, Dialog, DialogActions, DialogContent, DialogTitle } from '@mui/material';
import { addEstadosAlbaran, getEstadoAlbaran } from '../service/apiEstadosAlbaran';  

const CrearEstado = ({openDialog, onClose, setSnackbarMessage, setOpenSnackbar, setEstados}) => {

    const [formData, setFormData] = useState({
        est_alb_nombre: "", 
    });
    
    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData((prevData) => ({
            ...prevData,
            [name]: value,
        }));
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        if (formData.est_alb_nombre.trim() === '') {
            setSnackbarMessage('El nombre del estado no puede estar vacío');
            setOpenSnackbar(true);
            return;
        }

        try {
            const response = await addEstadosAlbaran(formData);  
            console.log('response', response)
            if (response) {
                setSnackbarMessage(`Almacén "${formData.est_alb_nombre}" creado con éxito`);
                setFormData({ est_alb_nombre: "" }); 
                const updateEstados = await getEstadoAlbaran();
                setEstados(updateEstados);
                setOpenSnackbar(true);
                onClose();
            } else {
                setSnackbarMessage('Hubo un error al crear el estado');
                setOpenSnackbar(true);
            }
        } catch (error) {
            console.error('Error al crear el estado:', error);
            setSnackbarMessage('Hubo un error al crear el estado');
            setOpenSnackbar(true);
        }
    };

    return (
        <>
        <Dialog open={openDialog} onClose={onClose} maxWidth="sm" fullWidth>
            <DialogTitle>Crear Estados Albaran</DialogTitle>
            <DialogContent>
                <Box>
                    <form onSubmit={handleSubmit}>
                        <TextField
                            label="Nombre del Estado"
                            variant="outlined"
                            fullWidth
                            name="est_alb_nombre" 
                            value={formData.est_alb_nombre}
                            onChange={handleChange}
                            margin="normal"
                            required
                        />

                        <Button
                            type="submit"
                            variant="contained"
                            color="primary"
                            fullWidth
                            sx={{ marginTop: '10px' }}
                        >
                            Crear Estado
                        </Button>
                    </form>
                </Box>
            </DialogContent>
            <DialogActions>
                <Button onClick={onClose} color="secondary">
                    Cancelar
                </Button>
            </DialogActions>
        </Dialog>
        </>
    );
};

export default CrearEstado;
