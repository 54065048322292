import React, { useEffect, useState } from "react";
import { Box, TextField, Button } from "@mui/material";
import ClearIcon from "@mui/icons-material/Clear";
import config from "../config";

const FilterBox = ({ origen, filters, setFilters, onFilterChange, data }) => {
  let validKeys;

  //Se valida el origen para agregar los filtros de cada interna.
  if (origen === "Almacen") {
    validKeys = Object.keys(config.filtrosAlmacen);
  } else if (origen === "Proveedor") {
    validKeys = Object.keys(config.filtrosProveedor);
  } else if (origen === "Estados") {
    validKeys = Object.keys(config.filtrosAlmacen);
  } else if (origen === "Residuos") {
    validKeys = Object.keys(config.filtrosRsiduos);
  } else if (origen === "Albaranes") {
    validKeys = Object.keys(config.filtrosAlbaran);
  }
  // Limpiar las clave inválidas en filters y agrega los campos del filtro
  useEffect(() => {
    const invalidKeys = Object.keys(filters).filter(
      (k) => !validKeys.includes(k)
    );

    if (invalidKeys.length > 0) {
      const cleanedFilters = Object.keys(filters)
        .filter((k) => validKeys.includes(k))
        .reduce((acc, k) => ({ ...acc, [k]: filters[k] }), {});

      setFilters(cleanedFilters);
    }
  }, [filters, setFilters]);

  const handleChange = (key, value) => {
    if (!validKeys.includes(key)) {
      console.error("🚨 Error: Clave inválida detectada:", key);
      return;
    }
    const newFilters = { ...filters, [key]: value };
    setFilters(newFilters);
    onFilterChange(data, newFilters);
  };

  const handleClear = () => {
    const clearedFilters = validKeys.reduce(
      (acc, key) => ({ ...acc, [key]: "" }),
      {}
    );
    setFilters(clearedFilters);
    onFilterChange(data, clearedFilters);
  };

  return (
    <Box gap={2} className="cajaFiltroInterna">
      {validKeys.map((key) => (
        <TextField
          key={key}
          label={`Filtrar por ${key}`}
          variant="outlined"
          value={filters[key] || ""}
          onChange={(e) => handleChange(key, e.target.value)}
          size="small"
          sx={{ flex: 2 }}
        />
      ))}
      <Button
        variant="contained"
        className="btnFiltro"
        onClick={handleClear}
        endIcon={<ClearIcon />}
      >
        Limpiar Filtros
      </Button>
    </Box>
  );
};

export default FilterBox;
