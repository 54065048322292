import React, { useState, useEffect } from "react";
import { getPrecioResiduo } from "../service/apiPrecioResiduoAlmacen";
import { getAlmacenes } from "../service/apiAlmacenes";
import { updatePrecioResiduo } from "../service/apiResiduos";
import {
  Box,
  Button,
  Typography,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Input,
  Paper,
  TextField,
  Snackbar,
} from "@mui/material";
import "../style/cajas.css";
import EuroIcon from "@mui/icons-material/Euro";
import Titulo from "../components/titulos";

const UpdatePrecioResiduoAlmacen = () => {
  const [precioresiduos, setPrecioResiduos] = useState([]);
  const [almacenes, setAlmacenes] = useState([]);
  const [residuos, setResiduos] = useState([]);
  const [preciosEditados, setPreciosEditados] = useState({});
  const [porcentajes, setPorcentajes] = useState();
  const [showSnackbar, setShowSnackbar] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");

  function limpiarHTML(texto) {
    if (typeof texto !== "string") {
      texto = String(texto);
    }
    return texto.replace(/<\/?[^0-9.]+(>|$)/g, "");
  }
  const inputPrecio = {
    width: "100%",
    margin: "0px",
    padding: "0px",
    border: "none",
    outline: "none",
    fontSize: "13px",
    transition: "border-color 0.3s",
    "& input[type=number]": {
      MozAppearance: "textfield",
      WebkitAppearance: "none",
      margin: 0,
      border: "none",
    },
    "& input[type=number]::-webkit-outer-spin-button, & input[type=number]::-webkit-inner-spin-button":
      {
        WebkitAppearance: "none",
        margin: 0,
        border: "none",
      },
  };

  const styleBoxButtom = {
    maxWidth: "80%",
    display: "flex",
    justifyContent: "flex-end",
    margin: "10px auto",
  };

  useEffect(() => {
    const fetchPrecioResiduo = async () => {
      try {
        const response = await getPrecioResiduo();
        setPrecioResiduos(response.precios);
        setAlmacenes(response.almacenes);
        setResiduos(response.residuos);
      } catch (error) {
        console.error("Error al obtener los residuos", error);
      }
    };
    //Traer los porcentajes desde bd
    const fetchAlmacenes = async () => {
      const response = await getAlmacenes();
      const porcentajesBD = response.map((almacen) => almacen.alm_porcentaje);
      setPorcentajes(porcentajesBD);
    };
    fetchAlmacenes();
    fetchPrecioResiduo();
  }, []);

  const obtenerPrecio = (res_id, alm_nombre) => {
    const residuo = precioresiduos.find((r) => r.res_id === res_id);
    return residuo && residuo[alm_nombre] !== undefined
      ? residuo[alm_nombre]
      : 0;
  };
  const handleInputChange = (event, res_id, alm_id) => {
    const regex = /^[0-9]*\.?[0-9]*$/;
    const nuevoPrecio = event.target.value || "";

    if (alm_id === 1) {
      setPreciosEditados((prev) => {
        const nuevosPrecios = { ...prev, [`${res_id}-${alm_id}`]: nuevoPrecio };
        if (!regex.test(nuevoPrecio)) {
          setSnackbarMessage("Debe agregar numeros en este campo");
          setShowSnackbar(true);
          nuevosPrecios[`${res_id}-1`] = "";
          almacenes.forEach((almacen, index) => {
            if (index > 1 && index < porcentajes.length) {
              nuevosPrecios[`${res_id}-${almacen.alm_id}`] = 0;
            }
          });
        } else {
          almacenes.forEach((almacen, index) => {
            if (index > 1 && index < porcentajes.length) {
              const porcentaje = parseFloat(porcentajes[index] || 0);
              const precioCalculado = nuevoPrecio / (1 + porcentaje / 100);
              nuevosPrecios[`${res_id}-${almacen.alm_id}`] = isNaN(
                precioCalculado
              )
                ? 0
                : precioCalculado.toFixed(2);
            }
          });
        }

        return nuevosPrecios;
      });
    } else {
      setPreciosEditados((prev) => {
        const nuevosPrecios = { ...prev, [`${res_id}-${alm_id}`]: nuevoPrecio };
        almacenes.forEach((almacen, index) => {
          if (index === 1) {
            if (!regex.test(nuevoPrecio)) {
              setSnackbarMessage("Debe agregar numeros en este campo");
              setShowSnackbar(true);
              nuevosPrecios[`${res_id}-${almacen.alm_id}`] = "";
            } else {
              nuevosPrecios[`${res_id}-${almacen.alm_id}`] = nuevoPrecio;
            }
          }
        });
        return nuevosPrecios;
      });
    }
  };
  const handleUpdate = async () => {
    const datosActualizados = Object.entries(preciosEditados).map(
      ([key, precio]) => {
        const [res_id, alm_id] = key.split("-");
        return { res_id, alm_id, precio };
      }
    );

    if (datosActualizados.length === 0) {
      setSnackbarMessage("No hay cambios para actualizar.");
      setShowSnackbar(true);
      return;
    }

    try {
      await updatePrecioResiduo(datosActualizados);
      setSnackbarMessage("Precios actualizados correctamente");
      setShowSnackbar(true);
    } catch (error) {
      console.error("Error al actualizar precios", error);

      setSnackbarMessage("Hubo un error al actualizar los precios");
      setShowSnackbar(true);
    }
  };
  const handleKeyDown = (event, res_id, alm_id, nextResId, nextAlmId) => {
    if (event.key === "Enter") {
      // Evitar que el formulario se envíe accidentalmente
      event.preventDefault();

      // Mover el foco al siguiente campo en la misma columna (almacén)
      const nextInput = document.getElementById(`${nextResId}-${nextAlmId}`);
      if (nextInput) {
        nextInput.focus();
      }
    }
  };

  return (
    <>
      <Titulo type="precio" name="Precios Residuos" />
      {/* <Box className="boxContainer"> */}
      <Box className="container">
        {/* <Box className="boxTablas"> */}
        <Box className="containerTablas">
          <TableContainer component={Paper} className="tableContainer">
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell className="tableHeaderCell">Residuo</TableCell>
                  {almacenes.map((almacen) => (
                    <TableCell className="tableHeaderCell" key={almacen.alm_id}>
                      {almacen.alm_nombre}
                    </TableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {residuos.map((residuo, rowIndex) => (
                  <TableRow
                    key={residuo.res_id}
                    sx={{
                      "&:nth-of-type(odd)": { backgroundColor: "#f5f5f5" },
                    }}
                  >
                    <TableCell className="tableContenidoResiduo">
                      {" "}
                      {residuo.res_nombre}
                    </TableCell>
                    {almacenes.map((almacen, colIndex) => (
                      <TableCell
                        className="tableContenido"
                        key={`${residuo.res_id}-${almacen.alm_id}`}
                      >
                        {colIndex <= 1 ? (
                          <Input
                            id={`${residuo.res_id}-${almacen.alm_id}`}
                            type="text"
                            sx={inputPrecio}
                            value={limpiarHTML(
                              preciosEditados[
                                `${residuo.res_id}-${almacen.alm_id}`
                              ] !== undefined
                                ? preciosEditados[
                                    `${residuo.res_id}-${almacen.alm_id}`
                                  ]
                                : obtenerPrecio(
                                    residuo.res_id,
                                    almacen.alm_nombre
                                  ) || "0"
                            )}
                            onChange={(event) =>
                              handleInputChange(
                                event,
                                residuo.res_id,
                                almacen.alm_id
                              )
                            }
                            onKeyDown={(event) => {
                              const nextRowIndex =
                                rowIndex + 1 < residuos.length
                                  ? rowIndex + 1
                                  : rowIndex;
                              const nextResId = residuos[nextRowIndex].res_id;
                              const nextAlmId = almacen.alm_id;
                              handleKeyDown(
                                event,
                                residuo.res_id,
                                almacen.alm_id,
                                nextResId,
                                nextAlmId
                              );
                            }}
                            tabIndex={
                              rowIndex * almacenes.length + colIndex + 1
                            } // Establece el orden de tabulación
                          />
                        ) : (
                          <span>
                            {preciosEditados[
                              `${residuo.res_id}-${almacen.alm_id}`
                            ] ||
                              obtenerPrecio(residuo.res_id, almacen.alm_nombre)}
                          </span>
                        )}
                      </TableCell>
                    ))}
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Box>
      </Box>
      <Box sx={styleBoxButtom}>
        <Button variant="contained" color="primary" onClick={handleUpdate}>
          Guardar Cambios
        </Button>
      </Box>
      <Snackbar
        open={showSnackbar}
        autoHideDuration={6000}
        onClose={() => setShowSnackbar(false)}
        message={snackbarMessage}
      />
    </>
  );
};

export default UpdatePrecioResiduoAlmacen;
