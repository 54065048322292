import React, { useState, useEffect } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Button,
  Paper,
  Snackbar,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
  Typography,
  Box,
  IconButton,
} from "@mui/material";
import { getAlmacenes, updateAlmacen } from "../service/apiAlmacenes";

import { FaEdit } from "react-icons/fa";
import CrearAlmacen from "./createAlmacen";
import "../style/cajas.css";

import FilterBox from "../components/filterBox";
import ArrowDropUpIcon from "@mui/icons-material/ArrowDropUp";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import AddIcon from "@mui/icons-material/Add";
import GetAppIcon from "@mui/icons-material/GetApp";
import UploadIcon from "@mui/icons-material/Upload";

import config from "../config";
import ImportarAlmacenes from "../componentsImport/importAlmacenes";
import * as XLSX from "xlsx";
import Titulo from "../components/titulos";

const UpdateAlmacenes = ({ datos }) => {
  const [almacenes, setAlmacenes] = useState([]);
  const [selectedAlmacen, setSelectedAlmacen] = useState({
    alm_id: 0,
    alm_nombre: "",
    alm_porcentaje: "",
  });
  const [showSnackbar, setShowSnackbar] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [openDialog, setOpenDialog] = useState(false);
  const [openDialogAlm, setOpenDialogAlm] = useState(false);
  const [sortDirection, setSortDirection] = useState("asc");
  const [filters, setFilters] = useState(config.filtrosAlmacen);
  const [datosFiltrados, setDatosFiltrados] = useState(datos || []);
  const [openDialogImport, setOpenDialogImport] = useState(false);

  useEffect(() => {
    const fetchAlmacenes = async () => {
      try {
        const response = await getAlmacenes();

        setAlmacenes(response);
      } catch (error) {
        console.error("Error al obtener los almacenes", error);
      }
    };
    fetchAlmacenes();
  }, []);
  useEffect(() => {
    setDatosFiltrados(filtrarAlmacenes(almacenes));
  }, [filters, almacenes]);
  const handleFilterChange = (name, value) => {
    setFilters((prevFilters) => ({
      ...prevFilters,
      [name]: value,
    }));
  };
  const filtrarAlmacenes = (almacenes) => {
    return almacenes.filter((almacen) => {
      return Object.entries(filters).every(([key, value]) => {
        if (!value || typeof value !== "string" || !value.trim()) return true;

        const field = almacen[`alm_${key.toLowerCase()}`];
        return field?.toString().toLowerCase().includes(value.toLowerCase());
      });
    });
  };
  const handleSort = () => {
    const sortedAlmacenes = [...almacenes].sort((a, b) => {
      // Normalizar los nombres y hacer comparación case-insensitive
      const nombreA = a.alm_nombre.trim().toLowerCase();
      const nombreB = b.alm_nombre.trim().toLowerCase();

      if (nombreA < nombreB) return sortDirection === "asc" ? -1 : 1;
      if (nombreA > nombreB) return sortDirection === "asc" ? 1 : -1;
      return 0; // Si son iguales
    });
    setAlmacenes(sortedAlmacenes);
    setSortDirection(sortDirection === "asc" ? "desc" : "asc"); // Cambiar la dirección de ordenación
  };
  const handleEditAlmacen = async () => {
    console.log("selectedAlmacen,", selectedAlmacen);

    try {
      if (
        selectedAlmacen.alm_nombre === "Maestro" &&
        selectedAlmacen.alm_porcentaje !== 0
      ) {
        setSnackbarMessage(
          "No puede cambiar el porcentaje del almacen Maestro"
        );
        setShowSnackbar(true);
      }
      const dataToSend = {
        alm_nombre: selectedAlmacen.alm_nombre,
        alm_tipo: selectedAlmacen.alm_tipo,
        alm_porcentaje: selectedAlmacen.alm_porcentaje,
      };

      const response = await updateAlmacen(selectedAlmacen.alm_id, dataToSend);
      if (response) {
        setSnackbarMessage("Almacen actualizado correctamente");
        setShowSnackbar(true);
        setOpenDialog(false);
        const updatedAlmacenes = await getAlmacenes();
        setAlmacenes(updatedAlmacenes);
      }
    } catch (error) {
      console.error("Error al actualizar almacen:", error);
      setSnackbarMessage("Error al actualizar el almacen");
      setShowSnackbar(true);
    }
  };
  const handleEdit = (almacen) => {
    setSelectedAlmacen(almacen);
    setOpenDialog(true);
  };
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setSelectedAlmacen((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };
  const handleOpenDialog = () => {
    setOpenDialogAlm(true);
  };
  const handleCloseDialog = () => {
    setOpenDialogAlm(false);
  };
  const handleCloseDialogImport = () => {
    setOpenDialogImport(false);
  };
  const handleOpenDialogImport = () => {
    setOpenDialogImport(true);
  };
  const handleDescargarExcel = () => {
    const datos = [
      {
        nombre: "",
        porcentaje: 0,
      },
      {
        nombre: "",
        porcentaje: 0,
      },
    ];
    const worksheet = XLSX.utils.json_to_sheet(datos);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "Datos");

    const excelBuffer = XLSX.write(workbook, {
      bookType: "xlsx",
      type: "array",
    });

    const blob = new Blob([excelBuffer], {
      type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
    });

    const url = URL.createObjectURL(blob);
    const link = document.createElement("a");
    link.href = url;
    link.download = "estructuraCargaAlmacenes.xlsx";
    link.click();

    URL.revokeObjectURL(url);
  };

  return (
    <>
      <Titulo type="almacen" name="Almacenes" />
      <Box className="filterBox">
        <FilterBox
          origen="Almacen"
          filters={filters}
          setFilters={setFilters}
          onFilterChange={handleFilterChange}
          data={almacenes}
        />
      </Box>
      <Box className="boxContainer">
        <Box className="boxTablas">
          <TableContainer component={Paper} className="tableContainer">
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell onClick={handleSort} className="tableHeaderCell">
                    Almacen
                    {sortDirection === "asc" ? (
                      <ArrowDropUpIcon className="headerTableIcons" />
                    ) : (
                      <ArrowDropDownIcon className="headerTableIcons" />
                    )}
                  </TableCell>
                  <TableCell className="tableHeaderCell">Porcentaje</TableCell>
                  <TableCell align="center" className="tableHeaderCell">
                    Editar
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {datosFiltrados.map((almacen) => (
                  <TableRow key={almacen.alm_id} className="tableCeldas">
                    {Object.keys(almacen).map(
                      (key, index) =>
                        key !== "alm_id" && (
                          <TableCell key={index}>{almacen[key]}</TableCell>
                        )
                    )}
                    <TableCell align="center">
                      {/* Botón de Editar */}
                      <IconButton
                        onClick={() => handleEdit(almacen)}
                        color="primary"
                        sx={{ fontSize: "1.3em" }}
                      >
                        <FaEdit />
                      </IconButton>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Box>
        <Box className="boxAcciones">
          <Typography className="headerAction" variant="h6">
            Acciones Almacenes
          </Typography>
          <Button
            variant="contained"
            className="btnAcciones"
            onClick={handleOpenDialog}
            endIcon={<AddIcon />}
          >
            Crear Almacen
          </Button>
          <Button
            variant="contained"
            className="btnAcciones"
            onClick={handleOpenDialogImport}
            endIcon={<UploadIcon />}
          >
            Importar Almacenes
          </Button>
          <Button
            variant="contained"
            className="btnAcciones"
            onClick={handleDescargarExcel}
            endIcon={<GetAppIcon />}
          >
            Descargar plantilla
          </Button>
        </Box>
      </Box>

      {/* Snackbar para mensajes */}
      <Snackbar
        open={showSnackbar}
        autoHideDuration={6000}
        onClose={() => setShowSnackbar(false)}
        message={snackbarMessage}
        sx={{ bottom: 100 }}
      />

      {/* Ventana de diálogo para editar almacen */}
      <Dialog
        open={openDialog}
        onClose={() => setOpenDialog(false)}
        maxWidth="md"
        fullWidth
        sx={{ borderRadius: "12px" }}
      >
        <DialogTitle sx={{ marginBottom: "20px", fontWeight: "bold" }}>
          Editar Almacen
        </DialogTitle>
        <DialogContent style={{ paddingTop: "10px" }}>
          {Object.keys(selectedAlmacen).map(
            (key) =>
              key !== "alm_id" && (
                <TextField
                  key={key}
                  label={key.replace("alm_", "").replace("_", " ")}
                  margin="dense"
                  variant="outlined"
                  fullWidth
                  name={key}
                  value={selectedAlmacen[key]}
                  onChange={handleInputChange}
                  disabled={
                    (selectedAlmacen.alm_nombre === "Maestro" ||
                      selectedAlmacen.alm_id === 1 ||
                      selectedAlmacen.alm_id === 2 ||
                      selectedAlmacen.alm_nombre === "Fianzas CHI") &&
                    key === "alm_porcentaje"
                  }
                />
              )
          )}
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => setOpenDialog(false)}
            color="error"
            sx={{ textTransform: "none" }}
          >
            Cancelar
          </Button>
          <Button
            onClick={handleEditAlmacen}
            color="primary"
            sx={{ textTransform: "none" }}
          >
            Guardar Cambios
          </Button>
        </DialogActions>
      </Dialog>

      {/* Aquí pasamos correctamente las funciones */}
      <CrearAlmacen
        openDialog={openDialogAlm}
        onClose={handleCloseDialog}
        setSnackbarMessage={setSnackbarMessage}
        setOpenSnackbar={setShowSnackbar}
        setAlmacenes={setAlmacenes}
      />
      <ImportarAlmacenes
        openDialog={openDialogImport}
        onClose={handleCloseDialogImport}
      />
    </>
  );
};

export default UpdateAlmacenes;
