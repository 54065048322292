import React, { useState, useEffect } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Button,
  Paper,
  Snackbar,
  Dialog,
  DialogActions,
  DialogContent,
  Box,
  DialogTitle,
  TextField,
  Typography,
  IconButton,
} from "@mui/material";
import { getProveedores, updateProveedores } from "../service/apiProveedores";
import { FaEdit } from "react-icons/fa";
import CrearProveedor from "./createProveedor";
import FilterBox from "../components/filterBox";
import AddIcon from "@mui/icons-material/Add";
import GetAppIcon from "@mui/icons-material/GetApp";
import ArrowDropUpIcon from "@mui/icons-material/ArrowDropUp";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import config from "../config";
import "../style/cajas.css";
import ImportarProveedores from "../componentsImport/importProveedores";
import UploadIcon from "@mui/icons-material/Upload";
import * as XLSX from "xlsx";
import Titulo from "../components/titulos";

const UpdateProveedores = ({ datos }) => {
  const [proveedores, setProveedores] = useState([]);
  const [showSnackbar, setShowSnackbar] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [openDialog, setOpenDialog] = useState(false);
  const [openDialogProv, setOpenDialogProv] = useState(false);
  const [loading, setLoading] = useState(true);
  const [selectProveedor, setSelectedProveedor] = useState({
    prov_id: 0,
    prov_nombre: "",
    prov_direccion: "",
    prov_codigo_postal: "",
    prov_poblacion: "",
    prov_provincia: "",
    prov_nif: "",
    prov_telefono: 0,
    prov_email: "",
    prov_cta_bancaria: "",
    prov_num_tarjeta_ecopay: 0,
    prov_total_op_anual: 0,
    prov_matricula: "",
  });
  const [datosFiltrados, setDatosFiltrados] = useState(datos);
  const [sortDirection, setSortDirection] = useState("asc");
  const [filters, setFilters] = useState(config.filtrosProveedor);
  const [openDialogImport, setOpenDialogImport] = useState(false);

  const celdas = [
    "Nombre",
    "Dirección",
    "Código Postal",
    "Población",
    "Provincia",
    "Teléfono",
    "NIF",
    "Email",
    "Cta Bancaria",
    "Tarjeta Ecopay",
    "Total Op Anual",
    "Matricula",
    "Editar",
  ];
  useEffect(() => {
    const fetchProveedores = async () => {
      try {
        const response = await getProveedores();
        setProveedores(response);
      } catch (error) {
        console.error("Error al obtener los proveedores", error);
      } finally {
        setLoading(false);
      }
    };
    fetchProveedores();
  }, []);
  useEffect(() => {
    setDatosFiltrados(filtrarProveedores(proveedores));
  }, [filters, proveedores]);
  const handleSort = () => {
    const sortedProveedores = [...proveedores].sort((a, b) => {
      const nomA = a.prov_nombre.trim().toLowerCase();
      const nomB = b.prov_nombre.trim().toLowerCase();

      if (nomA < nomB) return sortDirection === "asc" ? -1 : 1;
      if (nomA > nomB) return sortDirection === "asc" ? 1 : -1;
      return 0;
    });
    setProveedores(sortedProveedores);
    setSortDirection(sortDirection === "asc" ? "desc" : "asc");
  };
  const handleFilterChange = (name, value) => {
    setFilters((prevFilters) => ({
      ...prevFilters,
      [name]: value,
    }));
  };
  const validateNIF = (nif) => {
    const nifRegex = /^[0-9]{8}[A-Za-z]$/;
    return nifRegex.test(nif);
  };
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    if (name === "prov_nif" && !validateNIF(value)) {
      setSnackbarMessage("NIF no válido");
      setShowSnackbar(true);
      return;
    }
    setSelectedProveedor((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };
  const filtrarProveedores = (proveedores) => {
    return proveedores.filter((proveedor) => {
      return Object.entries(filters).every(([key, value]) => {
        if (!value || typeof value !== "string" || !value.trim()) return true; // Si el filtro está vacío, se ignora

        const field = proveedor[`prov_${key.toLowerCase()}`];
        return field?.toString().toLowerCase().includes(value.toLowerCase());
      });
    });
  };
  const handleEdit = (proveedor) => {
    setSelectedProveedor(proveedor);
    setOpenDialog(true);
  };
  const handleOpenDialog = () => {
    setOpenDialogProv(!openDialogProv);
  };
  const handleOpenDialogImport = (e) => {
    setOpenDialogImport(!openDialogImport);
  };
  const handleDescargarExcel = () => {
    const datos = [
      {
        nombre: "",
        direccion: "",
        codigo_postal: "",
        poblacion: "",
        provincia: "",
        nif: "",
        telefono: "",
        email: "",
        cta_bancaria: "",
        tarjeta_ecopay: "",
        matricula: "",
      },
      {
        nombre: "",
        direccion: "",
        codigo_postal: "",
        poblacion: "",
        provincia: "",
        nif: "",
        telefono: "",
        email: "",
        cta_bancaria: "",
        tarjeta_ecopay: "",
        matricula: "",
      },
    ];

    const worksheet = XLSX.utils.json_to_sheet(datos);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "Datos");

    const excelBuffer = XLSX.write(workbook, {
      bookType: "xlsx",
      type: "array",
    });

    const blob = new Blob([excelBuffer], {
      type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
    });

    const url = URL.createObjectURL(blob);
    const link = document.createElement("a");
    link.href = url;
    link.download = "estructuraCargaProveedores.xlsx";
    link.click();

    URL.revokeObjectURL(url);
  };
  const handleSubmitEdit = async () => {
    try {
      const dataToSend = { ...selectProveedor };
      const response = await updateProveedores(
        selectProveedor.prov_id,
        dataToSend
      );

      if (response) {
        setSnackbarMessage("Proveedor actualizado correctamente");
        setShowSnackbar(true);
        setOpenDialog(false);
        const updatedProveedores = await getProveedores();
        setProveedores(updatedProveedores);
      }
    } catch (error) {
      console.error("Error al actualizar proveedor:", error);
      setSnackbarMessage("Error al actualizar el proveedor");
      setShowSnackbar(true);
    }
  };
  return (
    <>
      <Titulo type="proveedor" name="Proveedores" />
      <Box className="filterBox">
        <FilterBox
          origen="Proveedor"
          filters={filters}
          setFilters={setFilters}
          onFilterChange={handleFilterChange}
          data={proveedores}
        />
      </Box>
      <Box className="boxContainer">
        <Box className="boxTablas">
          <TableContainer component={Paper} className="tableContainer">
            <Table>
              <TableHead>
                <TableRow>
                  {celdas.map((header) => (
                    <TableCell
                      className="tableHeaderCell"
                      key={header}
                      onClick={header === "Nombre" ? handleSort : undefined}
                    >
                      {header}
                      {header === "Nombre" &&
                        (sortDirection === "asc" ? (
                          <ArrowDropDownIcon className="headerTableIcons" />
                        ) : (
                          <ArrowDropUpIcon className="headerTableIcons" />
                        ))}
                    </TableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {loading ? (
                  <TableRow>
                    <TableCell colSpan={celdas.length} align="center">
                      Cargando Datos Proveedores...
                    </TableCell>
                  </TableRow>
                ) : (
                  datosFiltrados.map((proveedor) => (
                    <TableRow key={proveedor.prov_id} className="tableCeldas">
                      {Object.keys(proveedor).map(
                        (key, index) =>
                          key !== "prov_id" && (
                            <TableCell className="tableContenido" key={index}>
                              {proveedor[key]}
                            </TableCell>
                          )
                      )}
                      <TableCell align="center">
                        <IconButton
                          onClick={() => handleEdit(proveedor)}
                          color="primary"
                        >
                          <FaEdit />
                        </IconButton>
                      </TableCell>
                    </TableRow>
                  ))
                )}
              </TableBody>
            </Table>
          </TableContainer>
        </Box>
        <Box className="boxAcciones">
          <Typography className="headerAction" variant="h6">
            Acciones del Proveedor
          </Typography>
          <Button
            variant="contained"
            className="btnAcciones"
            onClick={handleOpenDialog}
            endIcon={<AddIcon />}
          >
            Crear Proveedor
          </Button>
          <Button
            variant="contained"
            className="btnAcciones"
            onClick={handleOpenDialogImport}
            endIcon={<UploadIcon />}
          >
            Importar Proveedores
          </Button>
          <Button
            variant="contained"
            className="btnAcciones"
            onClick={handleDescargarExcel}
            endIcon={<GetAppIcon />}
          >
            Descargar plantilla
          </Button>
        </Box>
      </Box>
      <Snackbar
        open={showSnackbar}
        autoHideDuration={6000}
        onClose={() => setShowSnackbar(false)}
        message={snackbarMessage}
      />
      {/* Diálogo para editar proveedor */}
      <Dialog
        open={openDialog}
        onClose={() => setOpenDialog(false)}
        maxWidth="md"
        fullWidth
      >
        <DialogTitle sx={{ marginBottom: "20px" }}>
          Editar Proveedor
        </DialogTitle>
        <DialogContent style={{ paddingTop: "10px" }}>
          {Object.keys(selectProveedor).map(
            (key) =>
              key !== "prov_id" && (
                <TextField
                  key={key}
                  label={key.replace("prov_", "").replace("_", " ")}
                  margin="dense"
                  variant="outlined"
                  fullWidth
                  name={key}
                  value={selectProveedor[key]}
                  onChange={handleInputChange}
                />
              )
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpenDialog(false)} color="error">
            Cancelar{" "}
          </Button>
          <Button onClick={handleSubmitEdit} color="primary">
            Guardar Cambios{" "}
          </Button>
        </DialogActions>
      </Dialog>

      <CrearProveedor
        openDialog={openDialogProv}
        onClose={handleOpenDialog}
        setSnackbarMessage={setSnackbarMessage}
        setOpenSnackbar={setShowSnackbar}
        setProveedores={setProveedores}
      />
      <ImportarProveedores
        openDialog={openDialogImport}
        onClose={handleOpenDialogImport}
      />
    </>
  );
};

export default UpdateProveedores;
