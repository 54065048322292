import React, { useState, useEffect } from "react";
import {
  getAlbaranes,
  getLineasAlbaran,
  updateAlbaran,
  updateLineasAlbaran,
  getTotalAlbaran,
} from "../service/apiAlbaran";
import Modal from "../modal/modal";
import DeleteIcon from "@mui/icons-material/Delete";
import {
  Typography,
  Box,
  TableContainer,
  Paper,
  Table,
  TableRow,
  TableHead,
  TableCell,
  TableBody,
  IconButton,
  Snackbar,
  Dialog,
  DialogTitle,
  DialogActions,
  DialogContent,
  TextField,
  Grid,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Button,
} from "@mui/material";
import { getProveedores } from "../service/apiProveedores";
import { getAlmacenes } from "../service/apiAlmacenes";
import { getEstadoAlbaran } from "../service/apiEstadosAlbaran";
import { getSeries } from "../service/apiSeries";
import { getMetodosDePago } from "../service/apiMetodosDePago";
import { getResiduoByIdAndAlmacen } from "../service/apiResiduos";
import { getResiduos } from "../service/apiResiduos";
import PdfGenerator from "../components/pdfGenerator";
import FilterBox from "../components/filterBox";
import config from "../config";
import { FaEdit } from "react-icons/fa";
import format from "date-fns/format";
import Titulo from "../components/titulos";
import { UploadOutlined } from "@mui/icons-material";
import SaveIcon from "@mui/icons-material/Save";
import ClearIcon from "@mui/icons-material/Clear";
import ArticleIcon from "@mui/icons-material/Article";

const UpdateAlbaran = ({ datos }) => {
  const [albaranes, setAlbaranes] = useState([]);
  const [filters, setFilters] = useState(config.filtrosAlbaran);
  const celdas = [
    "Id",
    "Consecutivo",
    "Serie",
    "REF",
    "Fecha",
    "Albarán Entrada",
    "Código",
    "Matricula",
    "Método Pago",
    "Almacen",
    "Estado",
    "Proveedor",
    "Peso Entrada",
    "Peso Salida",
    "Peso Final",
    "Editar Albarán",
    "Crear Albarán",
  ];
  const [loading, setLoading] = useState(true);
  const [datosFiltrados, setDatosFiltrados] = useState(datos);
  const [showSnackbar, setShowSnackbar] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [openDialog, setOpenDialog] = useState(false);
  const [selectAlbaran, setSelectAlbaran] = useState({
    Id: 0,
    REF: "",
    Fecha: "",
    Proveedor: "",
    Almacen: "",
    Estado: "",
    Nif: "",
    "Peso Entrada": "",
    "Peso Salida": "",
    "Peso Final": "",
  });
  const [selectUpdateAlbaran, setSelectUpdateAlbaran] = useState({
    alb_id: "",
    alb_su_ref: "",
    alb_fecha_creacion: "",
    alb_proveedor_id: 0,
    alb_almacen_id: 0,
    alb_estado_id: 0,
  });
  const [selectUpdateLineaAlbaran, setSelectUpdateLineaAlbaran] = useState({
    lin_id: 0,
    lin_alb_id: 0,
    lin_res_id: 0,
    cantXKilo: 0,
    precioXalmacen: 0,
    subtotal: 0,
    tipo_iva: 0,
  });
  const [lineasAlbaran, setLineasAlbaran] = useState([]);
  const [openDialogLine, setOpenDialogLine] = useState(false);
  const [proveedores, setProveedores] = useState([]);
  const [almacenes, setAlmacenes] = useState([]);
  const [estados, setEstados] = useState([]);
  const [series, setSeries] = useState([]);
  const [metodosPago, setMetodoPago] = useState([]);
  const [residuos, setResiduos] = useState([]);
  const [total, setTotal] = useState();
  const [totalParcial, setTotalParcial] = useState();
  const [showModal, setShowModal] = useState(false);
  const [modalMessage, setModalMessage] = useState("");
  const [pdfData, setPdfData] = useState(null);

  useEffect(() => {
    const fetchAlbaranes = async () => {
      try {
        const response = await getAlbaranes();
        setAlbaranes(response);
      } catch (error) {
        console.error("Error al obtener los Albaranes desde editar", error);
      } finally {
        setLoading(false);
      }
    };
    const fetchProveedores = async () => {
      try {
        const response = await getProveedores();
        setProveedores(response);
      } catch (error) {
        console.error("Error al obtener los proveedores desde editar", error);
      } finally {
        setLoading(false);
      }
    };
    const fetchAlmacenes = async () => {
      try {
        const response = await getAlmacenes();
        setAlmacenes(response);
      } catch (error) {
        console.error("Error al obtener los almacenes desde editar", error);
      } finally {
        setLoading(false);
      }
    };
    const fetchEstados = async () => {
      try {
        const response = await getEstadoAlbaran();
        setEstados(response);
      } catch (error) {
        console.error("Error al obtener los estados desde editar", error);
      } finally {
        setLoading(false);
      }
    };
    const fetchSeries = async () => {
      try {
        const response = await getSeries();
        setSeries(response);
      } catch (error) {
        console.error(
          "Error al obtener las series de albaran - desde editar",
          error
        );
      }
    };
    const fetchMetodosPago = async () => {
      try {
        const response = await getMetodosDePago();
        setMetodoPago(response);
      } catch (error) {
        console.error(
          "Error al obtener los metodos de pago. - desde editar",
          error
        );
      }
    };
    const fecthResiduos = async () => {
      try {
        const response = await getResiduos();
        setResiduos(response);
      } catch (error) {
        console.error("Error al obtener los residuos desde editar", error);
      }
    };

    fetchAlbaranes();
    fetchProveedores();
    fetchAlmacenes();
    fetchEstados();
    fetchSeries();
    fetchMetodosPago();
    fecthResiduos();
  }, []);

  useEffect(() => {
    setDatosFiltrados(filtrarAlbaranes(albaranes));
  }, [filters, albaranes]);

  const handleEditAlbaran = async (albaran) => {
    //Traer las lineas del albarán
    try {
      const response = await getLineasAlbaran(albaran.Id);
      if (response.length !== 0) {
        setLineasAlbaran(response);
        console.log("response lineas", response);

        setOpenDialogLine(true);
      } else {
        setLineasAlbaran([]);
        setSnackbarMessage("Este Albaran no contiene Lineas.");
        setShowSnackbar(true);
      }
    } catch (error) {
      console.error("Error al encontrar lineas de Albaran:", error);
      setSnackbarMessage("Error al encontrar lineas de Albaran.");
      setShowSnackbar(true);
    }

    try {
      const proveedorActual = proveedores.find(
        (prov) => prov.prov_nombre === albaran.Proveedor
      );

      const response = await getTotalAlbaran(proveedorActual.prov_id);
      setTotal(response.data.results[0]);
      setTotalParcial(response.data.results[0]);
      if (response.data.results[0].Total.toFixed(2) > 3000) {
        setModalMessage(
          `
          <p>
            El total para el proveedor <strong>${
              proveedorActual.prov_nombre
            }</strong> es mayor a 3000, es de
            <span style="color: red; font-weight: bold;">
              ${response.data.results[0].Total.toFixed(2)}
            </span>
          </p>
        `
        );
        setShowModal(true);
      }
      setSelectAlbaran(albaran);
      setOpenDialog(true);
    } catch (error) {
      console.error("Error al encontrar total para ese proveedor", error);
    }
  };
  const handleInputChangeAlbaran = async (e) => {
    const { name, value } = e.target;
    const selectedValue = e.target.value;

    if (name === "REF") {
      const keyName = "alb_su_ref";
      setSelectUpdateAlbaran((prevState) => ({
        ...prevState,
        [keyName]: value,
      }));
    } else if (name === "Fecha") {
      const keyName = "alb_fecha_creacion";
      setSelectUpdateAlbaran((prevState) => ({
        ...prevState,
        [keyName]: value,
      }));
    } else if (name === "Proveedor") {
      const keyName = "alb_proveedor_id";
      const selectedOption = proveedores.find(
        (proveedor) => proveedor.prov_nombre === selectedValue
      );
      const id = selectedOption.prov_id;
      setSelectUpdateAlbaran((prevState) => ({
        ...prevState,
        [keyName]: id,
      }));
    } else if (name === "Serie") {
      const keyName = "alb_serie_id";
      const selectedOption = series.find(
        (serie) => serie.ser_tipo === selectedValue
      );
      const id = selectedOption.ser_id;
      setSelectUpdateAlbaran((prevState) => ({
        ...prevState,
        [keyName]: id,
      }));
    } else if (name === "Metodo Pago") {
      const keyName = "alb_met_id";
      const selectedOption = metodosPago.find(
        (metodo) => metodo.met_nombre === selectedValue
      );
      const id = selectedOption.met_id;
      setSelectUpdateAlbaran((prevState) => ({
        ...prevState,
        [keyName]: id,
      }));
    } else if (name === "Almacen") {
      const keyName = "alb_almacen_id";
      const selectedOption = almacenes.find(
        (almacen) => almacen.alm_nombre === selectedValue
      );
      const id = selectedOption.alm_id;
      setSelectUpdateAlbaran((prevState) => ({
        ...prevState,
        [keyName]: id,
      }));

      if (Array.isArray(lineasAlbaran)) {
        const updatedLineas = await Promise.all(
          lineasAlbaran.map(async (linea) => {
            try {
              const response = await getResiduoByIdAndAlmacen(id, linea.Id);
              return {
                ...linea,
                Precio: response[0].precio,
                Total: (linea.Cantidad * response[0].precio).toFixed(2),
              };
            } catch (error) {
              console.error(
                "Error al obtener los precios desde editar albarán"
              );
              return linea; // En caso de error, no modificar la línea
            }
          })
        );
        setLineasAlbaran(updatedLineas);
      } else {
        console.error("Error en actualizacion de precios");
      }
    } else if (name === "Estado") {
      const keyName = "alb_estado_id";
      const selectedOption = estados.find(
        (estado) => estado.est_alb_nombre === selectedValue
      );
      const id = selectedOption.est_alb_id;
      setSelectUpdateAlbaran((prevState) => ({
        ...prevState,
        [keyName]: id,
      }));
    }
    setSelectAlbaran((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };
  const handleFilterChange = (name, value) => {
    setFilters((prevFilters) => ({
      ...prevFilters,
      [name]: value,
    }));
  };
  const filtrarAlbaranes = (albaranes) => {
    return albaranes.filter((albaran) => {
      return Object.entries(filters).every(([key, value]) => {
        if (!value || typeof value !== "string" || !value.trim()) return true;
        const field = albaran[key];
        return field?.toString().toLowerCase().includes(value.toLowerCase());
      });
    });
  };
  const filterObject = (obj) => {
    const filteredObj = {};
    // Recorrer todas las claves del objeto
    for (const key in obj) {
      // Comprobar si el valor de la propiedad no está vacío, null o undefined
      if (
        obj[key] !== null &&
        obj[key] !== undefined &&
        obj[key] !== "" &&
        obj[key] !== false &&
        obj[key] !== 0
      ) {
        filteredObj[key] = obj[key]; // Si tiene un valor, añadirlo al objeto filtrado
      }
    }

    return filteredObj;
  };
  const handleChangeLineasalbaran = async (index, field, value) => {
    const updatedLineas = [...lineasAlbaran]; // Clonar el array
    let updatedLinea = { ...updatedLineas[index] }; // Clonar la línea específica

    if (field === "Residuo") {
      const selectedAlmacen = almacenes.find(
        (almacen) => almacen.alm_nombre === selectAlbaran.Almacen
      );
      const selectedOption = residuos.find(
        (residuo) => residuo.res_nombre === value
      );

      if (!selectedAlmacen || !selectedOption) {
        console.error("Error: No se encontró el almacén o el residuo");
        return;
      }

      try {
        const response = await getResiduoByIdAndAlmacen(
          selectedAlmacen.alm_id,
          selectedOption.res_id
        );

        if (response.length === 0) {
          setSnackbarMessage(
            "Error: el residuo no tiene precios para ese almacén"
          );
          setShowSnackbar(true);
          return;
        }

        updatedLinea = {
          ...updatedLinea,
          Id: response[0].res_id,
          Residuo: response[0].res_nombre,
          Precio: response[0].precio,
          IVA: response[0].res_iva,
          Subtotal: response[0].res_iva
            ? (
                parseFloat(updatedLinea.Cantidad) *
                parseFloat(response[0].precio) *
                (1 + parseFloat(response[0].res_iva) / 100)
              ).toFixed(2)
            : (
                parseFloat(updatedLinea.Cantidad) *
                parseFloat(response[0].precio)
              ).toFixed(2),
        };
      } catch (error) {
        console.error(
          "Error al obtener los precios desde editar albarán",
          error
        );
        setSnackbarMessage(
          "Error: el residuo no tiene precios para ese almacén"
        );
        setShowSnackbar(true);
        return;
      }
    } else {
      updatedLinea[field] = value;
    }

    updatedLinea.Subtotal = (
      updatedLinea.Cantidad * updatedLinea.Precio
    ).toFixed(2);
    if (updatedLinea.IVA) {
      updatedLinea.Total = (
        updatedLinea.Subtotal *
        (1 + parseFloat(updatedLinea.IVA) / 100)
      ).toFixed(2);
    }

    updatedLineas[index] = updatedLinea;
    setLineasAlbaran(updatedLineas);

    let sumaLineas = updatedLineas.reduce(
      (lin, obj) => lin + parseFloat(obj.Subtotal),
      0
    );

    if (sumaLineas !== 0) {
      let TParcial = { ...total };
      let calculoFilas =
        parseFloat(totalParcial.Total) + parseFloat(sumaLineas);
      TParcial.Total = calculoFilas;
      setTotal(TParcial);
    } else {
      setTotal(totalParcial);
    }
    setSelectUpdateLineaAlbaran((prevState) => {
      let updatedState = Array.isArray(prevState) ? [...prevState] : [];

      // Incluir todas las líneas, editadas o no
      updatedLineas.forEach((linea) => {
        const existingIndex = updatedState.findIndex(
          (l) => l.lin_res_id === linea.Id
        );
        const changes = {
          lin_res_id: parseInt(linea.Id),
          cantXKilo: linea.Cantidad,
          precioXalmacen: linea.Precio,
          subtotal: parseFloat(linea.Precio) * parseFloat(linea.Cantidad),
          tipo_iva: linea.IVA || 0,
        };

        if (existingIndex !== -1) {
          updatedState[existingIndex] = {
            ...updatedState[existingIndex],
            ...changes,
          };
        } else {
          updatedState.push(changes);
        }
      });

      return updatedState;
    });
  };
  const handleSaveAlbaran = async () => {
    const dataAlbaran = filterObject(selectUpdateAlbaran);
    const alb_id = Number(selectAlbaran?.Id);

    if (!alb_id) {
      setSnackbarMessage(
        "No se puede guardar: el albarán no tiene un ID válido"
      );
      setShowSnackbar(true);
      return;
    }
    try {
      if (Object.keys(dataAlbaran).length > 0) {
        const responseAlbaran = await updateAlbaran(alb_id, dataAlbaran);
        if (responseAlbaran.status !== 200) {
          setSnackbarMessage("Error al actualizar el albarán.");
          setShowSnackbar(true);
          return;
        }
      }
      const lineasModificadas = selectUpdateLineaAlbaran
        .filter((linea) => linea.lin_res_id && Object.keys(linea).length > 1)
        .map((linea) => ({
          lin_res_id: Number(linea.lin_res_id),
          lin_alb_id: alb_id, // Se asegura que cada línea tenga el ID del albarán
          ...Object.fromEntries(
            Object.entries(linea)
              .filter(([key]) => key !== "lin_id") // Eliminamos `lin_id` si no es necesario
              .map(([key, value]) => [key, Number(value)]) // Convertimos valores a números
          ),
        }));

      if (lineasModificadas.length === 0) {
        setSnackbarMessage("No hay líneas modificadas para guardar.");
        setShowSnackbar(true);
        return;
      }

      const responseLineas = await updateLineasAlbaran(
        alb_id,
        lineasModificadas
      );

      if (responseLineas.status === 200) {
        setSnackbarMessage("Albarán y líneas actualizados con éxito.");
        setShowSnackbar(true);
        setSelectUpdateLineaAlbaran([]);
      } else {
        setSnackbarMessage("Error al actualizar las líneas del albarán.");
        setShowSnackbar(true);
      }
    } catch (error) {
      console.error("Error al actualizar el albarán", error);
      setSnackbarMessage("Ocurrió un error inesperado al guardar los cambios.");
      setShowSnackbar(true);
    }
  };
  const handleCloseModal = () => {
    setShowModal(false);
    setModalMessage("");
    setLineasAlbaran([]);
    setSelectUpdateLineaAlbaran([]);
  };
  const handleAddRow = () => {
    const newRow = {
      Id: "",
      Residuo: "",
      Cantidad: 0,
      Precio: 0,
      IVA: 0,
      Subtotal: 0,
    };
    const updateLineas = [...lineasAlbaran, newRow];
    setLineasAlbaran(updateLineas);
  };
  const deleteRow = (index) => {
    setLineasAlbaran((prevRows) => {
      const newRow = prevRows.filter((_, i) => i !== index);
      const sumaLineas = newRow.reduce(
        (lin, obj) => lin + parseFloat(obj.Subtotal),
        0
      );

      if (sumaLineas !== 0) {
        let TParcial = { ...total };
        let calculoFilas =
          parseFloat(totalParcial.Total) + parseFloat(sumaLineas);

        TParcial.Total = calculoFilas;
        setTotal(TParcial);
      } else if (newRow.length === 0) setTotal(totalParcial);

      return newRow;
    });
  };
  const handleClick = () => {
    console.log("albaran: ", selectAlbaran);
    console.log("lineas albaran: ", lineasAlbaran);
    const totalLineas = lineasAlbaran.reduce(
      (item, cont) => item + parseFloat(cont.Subtotal),
      0
    );

    // setPdfData({
    //   selectedSerie: selectAlbaran.Serie,
    //   consecutivo: selectAlbaran.Consecutivo,
    //   suRef: selectAlbaran.REF,
    //   selectedState: selectAlbaran.Estado,
    //   currentDate: selectAlbaran.Fecha,
    //   proveedor: selectAlbaran.Proveedor,
    //   codCliente: selectAlbaran.Codigo,
    //   almacenId: 0,
    //   albaranEntrada: selectAlbaran["Albarán Entrada"],
    //   selectMetodo: selectAlbaran["Metodo Pago"],
    //   nifCliente: selectAlbaran.Nif,
    //   numTarjeta: "",
    //   numCuenta: "",
    //   matricula: selectAlbaran.Matricula,
    //   rows: lineasAlbaran,
    //   allImpuestos: "",
    //   totalSum: totalLineas,
    //   pesoCamionEntrada: selectAlbaran["Peso Entrada"],
    //   pesoCamionSalida: selectAlbaran["Peso Salida"],
    //   pesoFinal: selectAlbaran["Peso Final"],
    // });

    console.log(pdfData);
  };

  return (
    <>
      <Titulo type="albaran" name="Editar Albaranes" />
      <Box key="filterBox" className="filterBox">
        <FilterBox
          origen="Albaranes"
          filters={filters}
          setFilters={setFilters}
          onFilterChange={handleFilterChange}
          data={albaranes}
        />
      </Box>
      {/* <Box className="boxContainer"> */}
      <Box className="container">
        {/* <Box className="boxTablas"> */}
        <Box className="containerTablas">
          <TableContainer
            key="tableContainer"
            component={Paper}
            className="tableContainer"
          >
            <Table>
              <TableHead>
                <TableRow key="titulos">
                  {celdas.map((header) => (
                    <TableCell className="tableHeaderCell" key={header}>
                      {header}
                    </TableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {loading ? (
                  <TableRow>
                    <TableCell
                      key="carga de datos"
                      colSpan={celdas.length}
                      align="center"
                    >
                      Cargando Datos Proveedores...
                    </TableCell>
                  </TableRow>
                ) : (
                  datosFiltrados.map((albaran) => (
                    <TableRow
                      key={`albaran-${albaran.Id}`}
                      className="tableCeldas"
                    >
                      {Object.keys(albaran).map((key, index) => {
                        if (key === "Fecha") {
                          const formattedDate = albaran[key]
                            ? format(new Date(albaran[key]), "dd/MM/yyyy")
                            : "";
                          return (
                            <TableCell key={`fecha-${albaran.Id}`}>
                              {formattedDate}
                            </TableCell>
                          );
                        }
                        //key !== "Id" && (
                        return (
                          <TableCell key={`${key}-${albaran.Id}`}>
                            {albaran[key]}
                          </TableCell>
                        );
                        // )
                      })}
                      <TableCell align="center">
                        <IconButton
                          title="Editar datos de albarán"
                          onClick={() => handleEditAlbaran(albaran)}
                          color="primary"
                        >
                          <FaEdit />
                        </IconButton>

                        {showModal && (
                          <Modal
                            message={modalMessage}
                            onClose={handleCloseModal}
                          />
                        )}
                      </TableCell>
                      <TableCell align="center">
                        <ArticleIcon color="primary" />
                      </TableCell>
                    </TableRow>
                  ))
                )}
              </TableBody>
            </Table>
          </TableContainer>
        </Box>
      </Box>
      <Snackbar
        open={showSnackbar}
        autoHideDuration={6000}
        onClose={() => setShowSnackbar(false)}
        message={snackbarMessage}
      />
      {/*  ========== edicion Datos de albaran ========== */}
      <Dialog
        open={openDialog}
        onClose={() => setOpenDialog(false)}
        maxWidth="lg"
        fullWidth
        disableEscapeKeyDown={true}
      >
        <DialogTitle
          variant="h5"
          sx={{ margin: "20px auto", textAlign: "center" }}
        >
          Editar Albarán
        </DialogTitle>
        <Typography
          variant="p"
          sx={{
            width: "90%",
            display: "block",
            margin: "10px auto",
            textAlign: "right",
          }}
        >
          Total albaran anual:
          {total?.Total !== undefined ? (
            <span style={{ color: total.Total > 3000 ? "red" : "green" }}>
              {total.Total.toFixed(2)}
            </span>
          ) : (
            "0"
          )}
        </Typography>
        {/* Renderizar DialogContent solo si selectAlbaran tiene datos */}
        {selectAlbaran.Id ? (
          <DialogContent
            key={`dialog-content-${selectAlbaran.Id}`}
            style={{ paddingTop: "10px" }}
          >
            <Grid container spacing={2}>
              {Object.keys(selectAlbaran).map((key) => {
                if (key === "Id") return null; // Excluir el campo Id

                if (key === "Fecha") {
                  return (
                    <Grid item xs={12} sm={6} key={`fecha-${selectAlbaran.Id}`}>
                      <TextField
                        label={key}
                        margin="dense"
                        variant="outlined"
                        fullWidth
                        name={key}
                        type="date"
                        value={
                          selectAlbaran.Fecha
                            ? new Date(selectAlbaran.Fecha)
                                .toISOString()
                                .split("T")[0]
                            : ""
                        }
                        onChange={handleInputChangeAlbaran}
                        InputLabelProps={{
                          shrink: true, // Asegura que la etiqueta no se superponga con el valor
                        }}
                      />
                    </Grid>
                  );
                }
                if (key === "Serie") {
                  return (
                    <Grid item xs={12} sm={6} key={`serie-${selectAlbaran.Id}`}>
                      <FormControl fullWidth margin="dense" variant="outlined">
                        <InputLabel>{key}</InputLabel>
                        <Select
                          value={selectAlbaran[key]}
                          onChange={handleInputChangeAlbaran}
                          label={key}
                          name={key}
                        >
                          {series.map((serie) => (
                            <MenuItem key={serie.ser_id} value={serie.ser_tipo}>
                              {serie.ser_tipo}
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    </Grid>
                  );
                }
                if (key === "Metodo Pago") {
                  return (
                    <Grid
                      item
                      xs={12}
                      sm={6}
                      key={`metodo-${selectAlbaran.Id}`}
                    >
                      <FormControl fullWidth margin="dense" variant="outlined">
                        <InputLabel>{key}</InputLabel>
                        <Select
                          value={selectAlbaran[key]}
                          onChange={handleInputChangeAlbaran}
                          label={key}
                          name={key}
                        >
                          {metodosPago.map((metodo) => (
                            <MenuItem
                              key={metodo.met_id}
                              value={metodo.met_nombre}
                            >
                              {metodo.met_nombre}
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    </Grid>
                  );
                }
                if (
                  key === "Peso Entrada" ||
                  key === "Peso Salida" ||
                  key === "Peso Final"
                ) {
                  return (
                    <Grid
                      item
                      xs={12}
                      sm={4}
                      key={`${key}-${selectAlbaran.Id}`}
                    >
                      <TextField
                        label={key}
                        margin="dense"
                        variant="outlined"
                        fullWidth
                        name={key}
                        value={selectAlbaran[key] || ""}
                        onChange={handleInputChangeAlbaran}
                      />
                    </Grid>
                  );
                }
                if (key === "Proveedor") {
                  return (
                    <Grid
                      item
                      xs={12}
                      sm={4}
                      key={`proveedor-${selectAlbaran.Id}`}
                    >
                      <FormControl fullWidth margin="dense" variant="outlined">
                        <InputLabel>{key}</InputLabel>
                        <Select
                          value={selectAlbaran[key]}
                          onChange={handleInputChangeAlbaran}
                          label={key}
                          name={key}
                        >
                          {proveedores.map((proveedor) => (
                            <MenuItem
                              key={proveedor.prov_id}
                              value={proveedor.prov_nombre}
                            >
                              {proveedor.prov_nombre}
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    </Grid>
                  );
                }
                if (key === "Almacen") {
                  return (
                    <Grid
                      item
                      xs={12}
                      sm={4}
                      key={`almacen-${selectAlbaran.Id}`}
                    >
                      <FormControl fullWidth margin="dense" variant="outlined">
                        <InputLabel>{key}</InputLabel>
                        <Select
                          value={selectAlbaran[key]}
                          onChange={handleInputChangeAlbaran}
                          label={key}
                          name={key}
                        >
                          {almacenes.map((almacen) => (
                            <MenuItem
                              key={almacen.alm_id}
                              value={almacen.alm_nombre}
                            >
                              {almacen.alm_nombre}
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    </Grid>
                  );
                }
                if (key === "Estado") {
                  return (
                    <Grid
                      item
                      xs={12}
                      sm={4}
                      key={`estado-${selectAlbaran.Id}`}
                    >
                      <FormControl fullWidth margin="dense" variant="outlined">
                        <InputLabel>{key}</InputLabel>

                        <Select
                          value={selectAlbaran[key]}
                          onChange={handleInputChangeAlbaran}
                          label={key}
                          name={key}
                        >
                          {estados.map((estado) => (
                            <MenuItem
                              key={estado.est_alb_id}
                              value={estado.est_alb_nombre}
                            >
                              {estado.est_alb_nombre}
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    </Grid>
                  );
                }
                return (
                  <Grid item xs={12} sm={6} key={`${key}-${selectAlbaran.Id}`}>
                    <TextField
                      label={key}
                      margin="dense"
                      variant="outlined"
                      fullWidth
                      name={key}
                      value={selectAlbaran[key] || ""}
                      onChange={handleInputChangeAlbaran}
                    />
                  </Grid>
                );
              })}
            </Grid>

            {/* Tabla de líneas de albarán */}
            <TableContainer>
              <DialogTitle sx={{ marginBottom: "5px" }}>
                {lineasAlbaran.length === 0
                  ? "No hay lineas disponibles"
                  : "Editar Lineas Albáran"}
              </DialogTitle>
              <Table>
                <TableBody>
                  {lineasAlbaran.map((item, index) => (
                    <TableRow key={item.id || index}>
                      <TableCell>
                        <Typography variant="p">{`${index + 1}`}</Typography>
                      </TableCell>
                      {Object.keys(item).map((key) => {
                        if (key === "Id") return null;
                        if (key === "Residuo") {
                          return (
                            <TableCell key={`linea-${index}-${key}`}>
                              <FormControl fullWidth margin="dense">
                                <InputLabel
                                  sx={{
                                    position: "absolute",
                                    top: item[key] ? "0" : "-8px",
                                    transition: "top 0.2s ease-in-out",
                                  }}
                                >
                                  {key}
                                </InputLabel>
                                <Select
                                  size="small"
                                  value={item[key]}
                                  name={key}
                                  label={key}
                                  onChange={(e) =>
                                    handleChangeLineasalbaran(
                                      index,
                                      key,
                                      e.target.value
                                    )
                                  }
                                  sx={{
                                    width: "300px",
                                  }}
                                >
                                  {residuos.map((residuo) => (
                                    <MenuItem
                                      key={residuo.res_id}
                                      value={residuo.res_nombre}
                                    >
                                      {residuo.res_nombre}
                                    </MenuItem>
                                  ))}
                                </Select>
                              </FormControl>
                            </TableCell>
                          );
                        }
                        return (
                          <TableCell key={`linea-${index}-${key}`}>
                            <TextField
                              size="small"
                              label={key}
                              value={item[key]}
                              onChange={(e) =>
                                handleChangeLineasalbaran(
                                  index,
                                  key,
                                  e.target.value
                                )
                              }
                              sx={{
                                width: "auto",
                                padding: "0",
                                margin: "0",
                              }}
                            />
                          </TableCell>
                        );
                      })}
                      <TableCell>
                        <Button
                          key={`eliminar_${index}`}
                          size="small"
                          color="error"
                          variant="outlined"
                          onClick={() => deleteRow(index)}
                        >
                          <DeleteIcon />
                        </Button>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
              <Button
                key="agregarFila"
                style={{ background: "#1abd9c", margin: "10px" }}
                variant="contained"
                onClick={handleAddRow}
              >
                Agregar Fila
              </Button>
            </TableContainer>
          </DialogContent>
        ) : (
          <DialogContent
            key="dialog-content-empty"
            style={{ paddingTop: "10px" }}
          >
            <Typography variant="body1" align="center">
              Cargando datos del albarán...
            </Typography>
          </DialogContent>
        )}
        <DialogActions>
          <Button
            onClick={() => setOpenDialog(false)}
            color="error"
            sx={{ textTransform: "none" }}
            endIcon={<ClearIcon />}
          >
            Cancelar
          </Button>
          <Button
            onClick={() => handleSaveAlbaran()}
            color="primary"
            sx={{ textTransform: "none" }}
            endIcon={<SaveIcon />}
          >
            Guardar Cambios
          </Button>
          <Button
            style={{ background: "#1abd9c" }}
            variant="contained"
            color="primary"
            endIcon={<ArticleIcon />}
            onClick={handleClick}
          >
            Crear Albarán
          </Button>
          {pdfData && <PdfGenerator data={pdfData} />}
        </DialogActions>
      </Dialog>
    </>
  );
};

export default UpdateAlbaran;
