import React from "react";
import "./estilos/Modal.css"; // Estilos opcionales
import { Typography } from "@mui/material";
const Modal = ({ message, onClose }) => {
  if (!message) return null;

  return (
    <div className="modal-overlay">
      <div className="modal">
        <Typography
          gutterBottom
          component="div"
          dangerouslySetInnerHTML={{ __html: message }}
        />
        <button className="close-btn" onClick={onClose}>
          Cerrar
        </button>
      </div>
    </div>
  );
};

export default Modal;
