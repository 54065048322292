import axios from "axios";
import API_URL from "../config";

export const getAlmacenes = async () => {
  try {
    const response = await axios.get(`${API_URL.Api}/almacen/getAlmacenes`);
    return response.data;
  } catch (error) {
    console.error("Error obteniendo almacenes ", error);
    return [];
  }
};
export const addAlmacen = async (almacenData) => {
  try {
    const response = await axios.post(
      `${API_URL.Api}/almacen/addAlmacen`,
      almacenData,
      {
        headers: {
          "Content-Type": "application/json",
        },
      }
    );
    return response.data;
  } catch (error) {
    console.error("Error al agregar el almacen", error);
    return null;
  }
};
export const updateAlmacen = async (id, almacenName) => {
  try {
    const response = await axios.put(
      `${API_URL.Api}/almacen/editAlmacen/${id}`,
      almacenName,
      {
        headers: {
          "Content-Type": "application/json",
        },
      }
    );

    return response.data;
  } catch (error) {
    console.error("Error al actualizar almacen", id, error);
    return null;
  }
};
export const deleteAlmacen = async (id) => {
  try {
    const response =
      await axios.delete(`${API_URL.Api}/almacen/deleteAlmacen/${id}
`);
    return response.data;
  } catch (error) {
    console.error("Error al eliminar almacen", id, error);
    return null;
  }
};
export const importarAlmacenes = async (formData) => {
  if (!(formData instanceof FormData)) {
    console.error("El archivo debe ser un FormData válido", formData);
    return null;
  }

  try {
    const response = await axios.post(
      `${API_URL.Api}/almacen/importar`,
      formData,
      {
        headers: {
          accept: "application/json",
        },
      }
    );

    return response.data;
  } catch (error) {
    console.error("Error al importar los almacenes ", error);
    return null;
  }
};
