import axios from "axios";
import API_URL from "../config";

export const getProveedores = async () => {
  try {
    const response = await axios.get(`${API_URL.Api}/proveedor/getProveedores`);

    return response.data;
  } catch (error) {
    console.error("Error obteniendo proveedores", error);
    return [];
  }
};
export const addProveedor = async (proveedorData) => {
  try {
    const response = await axios.post(
      `${API_URL.Api}/proveedor/addProveedor`,
      proveedorData,
      {
        headers: {
          "Content-Type": "application/json",
        },
      }
    );
    return response.data; // Devuelve los datos de respuesta de la API
  } catch (error) {
    console.error("Error al agregar el proveedor", error);
    return null;
  }
};
export const updateProveedores = async (id, proveedorData) => {
  if (typeof proveedorData != "object") {
    console.error("El proveedorData debe ser un objeto", proveedorData);
    return null;
  }
  try {
    const response = await axios.put(
      `${API_URL.Api}/proveedor/editarProveedor/${id}`,
      proveedorData,
      {
        headers: {
          "Content-Type": "application/json",
        },
      }
    );
    return response.data;
  } catch (error) {
    console.error("Error al actualizar el proveedor", error);
    return null;
  }
};

export const importarProveedores = async (formData) => {
  if (!(formData instanceof FormData)) {
    console.error("El archivo debe ser un FormData válido", formData);
    return null;
  }

  try {
    const response = await axios.post(
      `${API_URL.Api}/proveedor/importar`,
      formData,
      {
        headers: {
          accept: "application/json",
        },
      }
    );

    console.log("response.", response);

    return response.data;
  } catch (error) {
    console.error("Error al importar los proveedores ", error);
    return null;
  }
};
