import { Typography } from "@mui/material";
import React, { useState, useEffect } from "react";
import "../style/cajas.css";

import StoreMallDirectoryIcon from "@mui/icons-material/StoreMallDirectory";
import ReceiptLongIcon from "@mui/icons-material/ReceiptLong";
import BeenhereIcon from "@mui/icons-material/Beenhere";
import AddBusinessIcon from "@mui/icons-material/AddBusiness";
import RecyclingIcon from "@mui/icons-material/Recycling";
import EuroIcon from "@mui/icons-material/Euro";

const Titulo = ({ type, name }) => {
  const getICon = () => {
    switch (type) {
      case "albaran":
        return (
          <ReceiptLongIcon
            fontSize="medium"
            sx={{ verticalAlign: "middle", ml: "10px" }}
          />
        );
      case "almacen":
        return (
          <StoreMallDirectoryIcon
            fontSize="medium"
            sx={{ verticalAlign: "middle", ml: "10px" }}
          />
        );
      case "estado":
        return (
          <BeenhereIcon
            fontSize="medium"
            sx={{ verticalAlign: "middle", ml: "10px" }}
          />
        );
      case "proveedor":
        return (
          <AddBusinessIcon
            fontSize="medium"
            sx={{ verticalAlign: "middle", ml: "10px" }}
          />
        );
      case "residuo":
        return (
          <RecyclingIcon
            fontSize="medium"
            sx={{ verticalAlign: "middle", ml: "10px" }}
          />
        );
      case "precio":
        return (
          <EuroIcon
            fontSize="medium"
            sx={{ verticalAlign: "middle", ml: "10px" }}
          />
        );
      default:
        return null;
    }
  };

  return (
    <Typography variant="h4" className="headerPrincipal">
      {name}
      {getICon()}
    </Typography>
  );
};

export default Titulo;
