import React from "react";

import Navbar from "./navBar/navBar";
import CrearAlmacen from "./crudComponents/createAlmacen";
import CrearResiduo from "./crudComponents/createResiduos";
import CrearAlbaran from "./crudComponents/crearAlbaran";
import CrearProveedor from "./crudComponents/createProveedor";
import CrearEstados from "./crudComponents/createEstados";
import CrearPrecioResiduo from "./crudComponents/createPrecioResiduo";
import UpdateEstados from "./crudComponents/updateEstados";
import UpdateAlmacenes from "./crudComponents/updateAlmacenes";
import UpdateResiduos from "./crudComponents/updateResiduos";
import UpdateProveedores from "./crudComponents/updateProveedores";
import UpdatePrecioResiduoAlmacen from "./crudComponents/updatePrecioResiduoAlmacen";
import UpdateAlbaran from "./crudComponents/updateAlbaran";

import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate,
} from "react-router-dom";

//import logo from '../public/logo192.png'; // Asegúrate de tener el logo en la carpeta adecuada

function App() {
  return (
    <>
      <Router>
        <Navbar />
        <Routes>
          <Route path="/" element={<Navigate to="/crear-albaran" />} />
          {/* Crear  */}
          <Route path="/crear-albaran" element={<CrearAlbaran />} />
          <Route path="/crear-almacen" element={<CrearAlmacen />} />
          <Route path="/crear-residuo" element={<CrearResiduo />} />
          <Route path="/crear-proveedor" element={<CrearProveedor />} />
          <Route path="/crear-estados" element={<CrearEstados />} />
          <Route path="/crear-precio" element={<CrearPrecioResiduo />} />

          {/* Editar */}
          <Route path="/editar-albaran" element={<UpdateAlbaran />} />

          {/* Actualizar */}
          <Route path="/estados-albaran" element={<UpdateEstados />} />
          <Route path="/almacenes" element={<UpdateAlmacenes />} />
          <Route path="/residuos" element={<UpdateResiduos />} />
          <Route path="/proveedores" element={<UpdateProveedores />} />
          <Route
            path="/precioResiduoAlmacen"
            element={<UpdatePrecioResiduoAlmacen />}
          />
        </Routes>
      </Router>
    </>
  );
}

export default App;
