import axios from "axios";
import API_URL from "../config";

export const getMetodosDePago = async () => {
  try {
    const response = await axios.get(
      `${API_URL.Api}/metodosPago/getMetodosPago`
    );

    return response.data;
  } catch (error) {
    console.error("Error al obtener los Metodos de pago", error);
    return [];
  }
};
