import React, { useState, useEffect } from "react";
import "../style/form.css";
import { addSeriesConsecutivo } from "../service/apiSeries";
import { ProveedoresSelect } from "../components/proveedorSelect";
import { EstadosSelect } from "../components/estadosSelect";
import { ResiduosTable } from "../components/residuosTable";
import { SeriesSelect } from "../components/seriesSelect";
import { FormControl, TextField } from "@mui/material";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import esLocale from "date-fns/locale/es";
import { AlmacenesSelect } from "../components/almacenesSelect";
import PdfGenerator from "../components/pdfGenerator";
import Modal from "../modal/modal";
import { Button, Select, MenuItem, InputLabel, Box, Grid } from "@mui/material";
import { addAlbaran, addLinea, getTotalAlbaran } from "../service/apiAlbaran";
import { getMetodosDePago } from "../service/apiMetodosDePago";
import Titulo from "../components/titulos";

function CrearAlbaran() {
  const [currentDate, setCurrentDate] = useState(new Date());
  const [suRef, setSuRef] = useState("");
  const [estadoAlbaran, setEstadoAlbaran] = useState("");
  const [albaranEntrada, setAlbaranEntrada] = useState("");
  const [codCliente, setCodCliente] = useState("");
  const [almacenId, setAlmacenId] = useState(null);
  const [proveedor, setProveedor] = useState("");
  const [selectedSerie, setSelectedSerie] = useState("");
  const [selectedState, setSelectedState] = useState("");
  const [isDisabled, setIsDisabled] = useState(true);
  const [showModal, setShowModal] = useState(false);
  const [modalMessage, setModalMessage] = useState("");
  const [consecutivo, setConsecutivo] = useState(null);
  const [rows, setRows] = useState([]);
  const [pdfData, setPdfData] = useState(null);
  const [nifCliente, setNifCliente] = useState("");
  const [numTarjeta, setNumTarjeta] = useState("");
  const [numCuenta, setNumCuenta] = useState("");
  const [matricula, setMatricula] = useState("");
  const [habilitarMatricula, setHabilitarMatricula] = useState(false);
  const [pesoCamionEntrada, setpesoCamionEntrada] = useState(0);
  const [pesoCamionSalida, setpesoCamionSalida] = useState(0);
  const [pesoFinal, setpesoFinal] = useState(0);
  const [metodoPago, setMetodoPago] = useState([]);
  const [selectMetodo, setSelectMetodo] = useState("");
  const [totalAlbaran, setTotalAlbaran] = useState("0.00");
  const [albaranesAnio, setAlbaranesAnio] = useState("0");
  const [parcialPrecio, setParcialPrecio] = useState();

  useEffect(() => {
    const today = new Date();
    setCurrentDate(today);
    if (pesoCamionEntrada && pesoCamionSalida) {
      const final =
        parseFloat(pesoCamionEntrada) - parseFloat(pesoCamionSalida);
      setpesoFinal(final || 0); // Asegura que no sea NaN
    }
  }, [pesoCamionEntrada, pesoCamionSalida]);
  useEffect(() => {
    const fetchMetodosDePago = async () => {
      try {
        const response = await getMetodosDePago();
        setMetodoPago(response);
        if (response.length > 0) {
          setSelectMetodo(response[0].met_id);
        }
      } catch (error) {
        console.error("Error al obtener los metodos de pago", error);
      }
    };
    fetchMetodosDePago();
  }, []);
  const handleChange = (event) => {
    const newValue = event.target.value;
    setSelectMetodo(newValue);
  };
  const handleDateDoubleClick = () => {
    setIsDisabled(false);
    setShowModal(true);
    setModalMessage("¡Este campo se habilitará. para ser editado!");
  };
  const handleCloseModal = () => {
    setShowModal(false);
    setModalMessage("");
  };
  const handleDateChange = (newValue) => {
    setCurrentDate(newValue);
    setIsDisabled(true);
  };
  const handleChangeMatricula = (event) => {
    if (habilitarMatricula) {
      setMatricula(event.target.value);
    }
  };
  const handleDoubleClick = () => {
    setHabilitarMatricula(true); // Habilita la edición
    setShowModal(true); // Muestra el modal
    setModalMessage("¡Este campo se habilitará para ser editado!");
  };
  const handleAlmacenChange = (id, name) => {
    setAlmacenId({ id: id, nombre: name });
  };
  const handleEstadoChange = (newState) => {
    setSelectedState(newState);
  };
  const handleSelectedSerieChange = (newSerie) => {
    setSelectedSerie(newSerie);
  };
  const handleSelectedProveedor = (newProveedor) => {
    if (!newProveedor) {
      setTotalAlbaran(0);
      setAlbaranesAnio(0);
    }
    setProveedor(newProveedor);
  };
  const handleRowsUpdate = (updatedRows) => {
    if (!updatedRows) return;

    if (updatedRows.length === 0) {
      setTotalAlbaran(parcialPrecio);
      setRows([]);
      return;
    }
    const newRows = updatedRows.map((row) => ({ ...row }));
    let calculoDeFilas = newRows.reduce((cont, obj) => cont + obj.total, 0);
    if (calculoDeFilas > 0) {
      let totalCalculado = parseFloat(parcialPrecio) + calculoDeFilas;
      setTotalAlbaran(totalCalculado);

      if (totalCalculado > 3000) {
        setShowModal(true);
        setModalMessage(
          `Atención: El total del albarán está por encima del monto anual permitido`
        );
      }
    } else {
      setTotalAlbaran(parcialPrecio);
    }
    setRows(updatedRows);
  };
  const handleChangePesoEntrada = (value) => {
    setpesoCamionEntrada(value);
  };
  const handleChangePesoSalida = (value) => {
    setpesoCamionSalida(value);
  };
  const handleConsecutivo = async () => {
    if (!selectedSerie) {
      setShowModal(true);
      setModalMessage("Debe Seleccionar la serie del Albarán");
    } else {
      const result = await addSeriesConsecutivo(selectedSerie, consecutivo);
      if (result.includes("Consecutivo agregado con exito con id:")) {
        setConsecutivo(consecutivo + 1);
      }
    }
  };
  const handleConsecutivoChange = (nuevoConsecutivo) => {
    setConsecutivo(nuevoConsecutivo + 1 || 0);
  };
  const handleChangeIdCliente = async (valorIdCliente) => {
    if (totalAlbaran === 0) {
      setTotalAlbaran(totalAlbaran);
      setParcialPrecio(totalAlbaran);
    }
    if (valorIdCliente) {
      const response = await getTotalAlbaran(valorIdCliente);
      const totalAlbaranxProveedor = response.data.results[0];
      if (
        totalAlbaranxProveedor.Total !== null &&
        totalAlbaranxProveedor.Total !== undefined
      ) {
        const total = Number(totalAlbaranxProveedor.Total).toFixed(2);
        setTotalAlbaran(total);
        setParcialPrecio(total);
        setAlbaranesAnio(totalAlbaranxProveedor.Albaranes);

        if (parseFloat(total) > 3000) {
          setShowModal(true);
          setModalMessage(
            `RECUERDE: ${totalAlbaranxProveedor.Proveedor},
            esta por encima del monto anual permitido`
          );
        } else if (parseFloat(total) > 0 || parseFloat(total) < 3000) {
          setTotalAlbaran(total);
          setParcialPrecio(total);
          setAlbaranesAnio(totalAlbaranxProveedor.Albaranes);
        } else {
          setTotalAlbaran(0.0);
          setParcialPrecio(0.0);
          setAlbaranesAnio(0);
        }
      }
      setCodCliente(valorIdCliente);
    } else {
      setCodCliente("");
    }
  };
  const handleNifCliente = (valorNifCliente) => {
    if (valorNifCliente) {
      setNifCliente(valorNifCliente);
    } else {
      setNifCliente("");
    }
  };
  const handleNumTarjeta = (valorNumTarjeta) => {
    if (valorNumTarjeta) {
      setNumTarjeta(valorNumTarjeta);
    } else {
      setNumTarjeta("");
    }
  };
  const handleNumCuenta = (valorNumCuenta) => {
    if (valorNumCuenta) {
      setNumCuenta(valorNumCuenta);
    } else {
      setNumCuenta("");
    }
  };
  const handleMatricula = (valorMatricula) => {
    if (valorMatricula) {
      setMatricula(valorMatricula);
    } else {
      setMatricula("");
    }
  };
  const handleCodEstadoAlbaran = (codEstado) => {
    if (codEstado) {
      setEstadoAlbaran(codEstado);
    } else {
      setEstadoAlbaran("");
    }
  };
  const handleClick = async (e) => {
    e.preventDefault();
    handleConsecutivo();

    if (!selectedSerie) {
      setShowModal(true);
      setModalMessage("Debe seleccionar una Serie Albarán");
      return;
    } else if (!selectedState) {
      setShowModal(true);
      setModalMessage("Debe seleccionar un Estado de Albarán");
      return;
    } else if (!metodoPago) {
      setShowModal(true);
      setModalMessage("Debe seleccionar un Método de pago");
    } else if (!proveedor) {
      setShowModal(true);
      setModalMessage("Debe seleccionar un Proveedor");
      return;
    } else if (!codCliente) {
      setShowModal(true);
      setModalMessage("Debe diligenciar el campo Código Cliente ");
      return;
    } else if (!almacenId) {
      setShowModal(true);
      setModalMessage("Debe seleccionar un Almacen");
      return;
    } else if (rows.length === 0) {
      setShowModal(true);
      setModalMessage("Debe diligenciar los Residuos para crear el Albarán");
      return;
    }
    const allImpuestos = rows.map((row) => row.impuesto);

    //Validar cantidades
    const areQuantitiesValid = rows.every((row) => {
      return typeof row.cantidad === "number" || row.cantidad > 0;
    });

    if (!areQuantitiesValid) {
      setShowModal(true);
      setModalMessage(
        "Por favor, verifica que todas las filas tengan cantidades válidas antes de proceder."
      );
      return;
    }
    const totalSum = rows.reduce(
      (accumulator, item) => accumulator + item.total,
      0
    );

    try {
      // Crear el albarán
      const albaranData = {
        alb_proveedor_id: codCliente,
        alb_almacen_id: almacenId.id,
        alb_estado_id: estadoAlbaran,
        alb_su_ref: suRef,
        alb_consecutivo: parseInt(consecutivo),
        alb_codigo: codCliente,
        alb_matricula: matricula,
        alb_entrada: albaranEntrada,
        alb_serie_id: selectedSerie,
        alb_met_id: selectMetodo,
        alb_peso_entrada: pesoCamionEntrada,
        alb_peso_salida: pesoCamionSalida,
        alb_peso_final: pesoFinal,
      };

      const albaranId = await addAlbaran(albaranData);
      // Crear las líneas de albarán
      for (const row of rows) {
        const lineaData = {
          lin_alb_id: albaranId,
          lin_res_id: row.articulo,
          cantXKilo: parseFloat(row.cantidad),
          precioXalmacen: parseFloat(row.precio),
          subtotal: parseFloat(row.subtotal),
          tipo_iva: parseFloat(row.impuesto),
        };
        await addLinea(lineaData);
      }

      if (albaranId) {
        setPdfData({
          selectedSerie,
          consecutivo,
          suRef,
          selectedState,
          currentDate,
          proveedor,
          codCliente,
          almacenId,
          albaranEntrada,
          selectMetodo,
          nifCliente,
          numTarjeta,
          numCuenta,
          matricula,
          rows,
          allImpuestos,
          totalSum,
          pesoCamionEntrada,
          pesoCamionSalida,
          pesoFinal,
        });
      }
      window.location.reload();
    } catch (error) {
      console.error(error);
    }
  };
  const recibeAlmacenID = (id, name) => {
    console.log("Id: ", id);
    console.log("name: ", name);

    setAlmacenId({ id: id, nombre: name });
  };
  return (
    <>
      <Titulo type="albaran" name="Ingreso de albaranes" />
      <label
        style={{
          width: "90%",
          display: "block",
          textAlign: "right",
          margin: "15px",
          fontWeight: "bold",
        }}
      >
        Total Proveedor:{" "}
        <span
          style={{
            color:
              totalAlbaran > 3000
                ? "red"
                : totalAlbaran > 0
                ? "green"
                : "green",
          }}
        >
          {totalAlbaran != null && !isNaN(Number(totalAlbaran))
            ? Number(totalAlbaran).toFixed(2)
            : "0.00"}
        </span>
        <span> Total Albaranes: {albaranesAnio} </span>
      </label>
      <form className="form-container">
        <div className="form-grid">
          <div>
            <SeriesSelect
              onSerieChange={handleSelectedSerieChange}
              ValorConsecutivo={handleConsecutivoChange}
            />
          </div>
          <div>
            <TextField
              size="small"
              label="Consecutivo"
              variant="outlined"
              value={consecutivo || 0}
              fullWidth
              InputLabelProps={{
                shrink: true, // Forza el label a mantenerse arriba incluso si está vacío
              }}
            />
          </div>
          <div>
            <TextField
              size="small"
              label="Su Ref"
              variant="outlined"
              fullWidth
              value={suRef || ""}
              onChange={(e) => setSuRef(e.target.value)}
            />
          </div>
          <div>
            <EstadosSelect
              onEstadoChange={handleEstadoChange}
              codCliente={handleCodEstadoAlbaran}
            />
          </div>
          <div
            title="Doble clic para editar la fecha"
            onDoubleClick={handleDateDoubleClick}
          >
            <LocalizationProvider
              dateAdapter={AdapterDateFns}
              adapterLocale={esLocale}
            >
              <DatePicker
                className="campoFecha"
                title="Doble clic para editar fecha"
                label="Fecha"
                value={currentDate}
                onChange={handleDateChange}
                readOnly={isDisabled}
                inputFormat="dd/MM/yyyy"
              />
            </LocalizationProvider>

            {/* Mostrar el modal si showModal es true */}
            {showModal && (
              <Modal message={modalMessage} onClose={handleCloseModal} />
            )}
          </div>
          <div>
            {
              //Proveedor
            }
            <ProveedoresSelect
              onProveedorChange={handleSelectedProveedor}
              valorIdCliente={handleChangeIdCliente}
              nifCliente={handleNifCliente}
              numTarjeta={handleNumTarjeta}
              numCuenta={handleNumCuenta}
              matricula={handleMatricula}
            />
          </div>
          <div>
            <TextField //Codigo Cliente
              size="small"
              style={{ width: "48%", margin: "auto 2% auto 0" }}
              label="Código Cliente"
              variant="outlined"
              fullWidth
              value={codCliente || ""}
              readOnly={true}
            />
            <TextField // Matrícula
              size="small"
              title="Seleccione un proveedor y de doble clic para editar matricula"
              style={{ width: "48%", margin: "auto 0 auto 2%" }}
              label="Matrícula"
              variant="outlined"
              fullWidth
              readOnly={!habilitarMatricula}
              onChange={handleChangeMatricula}
              onDoubleClick={handleDoubleClick}
              value={matricula || ""}
            />
          </div>
          <div>
            <AlmacenesSelect
              recibirID={recibeAlmacenID}
              onAlmacenChange={handleAlmacenChange}
            />
          </div>

          <div>
            <TextField
              size="small"
              label="Albarán de entrada"
              variant="outlined"
              value={albaranEntrada}
              fullWidth
              onChange={(e) => setAlbaranEntrada(e.target.value)}
              className="form-control"
            />
          </div>
          <div>
            <FormControl size="small" fullWidth>
              <InputLabel id="metodo-label">Método de pago</InputLabel>
              <Select
                labelId="metodo-label"
                value={selectMetodo}
                label="Método de Pago"
                onChange={handleChange}
              >
                {metodoPago.length === 0 ? (
                  <MenuItem disabled>No se encontraron métodos </MenuItem>
                ) : (
                  metodoPago.map((metodo) => (
                    <MenuItem key={metodo.met_id} value={metodo.met_id}>
                      {metodo.met_nombre}
                    </MenuItem>
                  ))
                )}
              </Select>
            </FormControl>
          </div>
        </div>
      </form>
      <div className="table-container">
        <ResiduosTable
          almacenId={almacenId}
          rows={rows}
          setRows={setRows}
          onRowsUpdate={handleRowsUpdate}
        />
      </div>
      <Box
        sx={{
          maxWidth: "80%",
          margin: "20px auto",
          padding: "20px",
          borderRadius: "10px",
          boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.1)",
          backgroundColor: "#f5f5f5",
          display: "flex",
          flexDirection: "column",
        }}
      >
        <h3 style={{ textAlign: "left" }} className="form-subtitle">
          Peso de Carga
        </h3>
        <Grid container spacing={3} justifyContent="center" alignItems="center">
          <Grid item xs={4}>
            <TextField
              label="Peso Camion Entrada"
              type="number"
              value={pesoCamionEntrada}
              onChange={(e) => handleChangePesoEntrada(e.target.value)}
              fullWidth
              margin="normal"
              variant="outlined"
              size="small" // Ajusta el tamaño de los inputs
            />
          </Grid>
          <Grid item xs={4}>
            <TextField
              label="Peso Camion Salida"
              type="number"
              value={pesoCamionSalida}
              onChange={(e) => handleChangePesoSalida(e.target.value)}
              fullWidth
              margin="normal"
              variant="outlined"
              size="small"
            />
          </Grid>
          <Grid item xs={4}>
            <TextField
              label="Peso Final"
              type="number"
              value={pesoFinal}
              InputProps={{
                readOnly: true,
              }}
              fullWidth
              margin="normal"
              variant="outlined"
              size="small"
            />
          </Grid>
        </Grid>
      </Box>
      <div style={{ margin: "40px auto", textAlign: "right", width: "80%" }}>
        <Button
          style={{ background: "#1abd9c", margin: "10px auto" }}
          variant="contained"
          color="primary"
          onClick={handleClick}
        >
          Crear Albarán
        </Button>
        {showModal && (
          <Modal message={modalMessage} onClose={handleCloseModal} />
        )}
        {pdfData && <PdfGenerator data={pdfData} />}
      </div>
    </>
  );
}

export default CrearAlbaran;
