import React, { useState, useEffect } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Button,
  Paper,
  Snackbar,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
  Box,
  Typography,
  IconButton,
} from "@mui/material";
import {
  getEstadoAlbaran,
  updateEstadosAlbaran,
  deleteEstadoAlbaran,
} from "../service/apiEstadosAlbaran";
import { FaEdit } from "react-icons/fa";
import CrearEstados from "./createEstados";
import "../style/cajas.css";
import FilterBox from "../components/filterBox";
import config from "../config";
import ArrowDropUpIcon from "@mui/icons-material/ArrowDropUp";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import AddIcon from "@mui/icons-material/Add";
import Titulo from "../components/titulos";

const UpdateEstados = ({ datos }) => {
  const [estados, setEstados] = useState([]);
  const [showSnackbar, setShowSnackbar] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [openDialog, setOpenDialog] = useState(false);
  const [openDialogEst, setOpenDialogEst] = useState(false);
  const [selectedEstado, setSelectedEstado] = useState({
    est_alb_nombre: "",
    est_alb_id: 0,
  });
  const [filters, setFilters] = useState(config.filtrosEstados);
  const [sortDirection, setSortDirection] = useState("asc");
  const [datosFiltrados, setDatosFiltrados] = useState(datos || []);

  useEffect(() => {
    const fetchEstados = async () => {
      try {
        const response = await getEstadoAlbaran();
        setEstados(response);
      } catch (error) {
        console.error("Error al obtener los estados:", error);
      }
    };
    fetchEstados();
  }, []);

  useEffect(() => {
    setDatosFiltrados(filtrarEstados(estados));
  }, [filters, estados]);

  const handleFilterChange = (name, value) => {
    setFilters((prevFilters) => ({
      ...prevFilters,
      [name]: value,
    }));
  };
  const filtrarEstados = (estados) => {
    return estados.filter((estado) => {
      return Object.entries(filters).every(([key, value]) => {
        if (!value || typeof value !== "string" || !value.trim()) return true;

        const field = estado[`est_alb_${key.toLowerCase()}`];
        return field
          ?.toString()
          .toLowerCase()
          .includes(value.toLocaleLowerCase());
      });
    });
  };

  const handleSort = () => {
    const sortedEstados = [...estados].sort((a, b) => {
      const nomA = a.est_alb_nombre.trim().toLowerCase();
      const nomB = b.est_alb_nombre.trim().toLowerCase();

      if (nomA < nomB) return sortDirection === "asc" ? -1 : 1;
      if (nomA > nomB) return sortDirection === "asc" ? 1 : -1;
      return 0;
    });
    setEstados(sortedEstados);
    setSortDirection(sortDirection === "asc" ? "desc" : "asc");
  };
  const handleEdit = (estado) => {
    setSelectedEstado(estado);
    setOpenDialog(true);
  };

  const handleSubmitEdit = async () => {
    try {
      const dataToSend = { est_alb_nombre: selectedEstado.est_alb_nombre };

      const response = await updateEstadosAlbaran(
        selectedEstado.est_alb_id,
        dataToSend
      );
      if (response) {
        setSnackbarMessage("Estado actualizado correctamente");
        setShowSnackbar(true);
        setOpenDialog(false);
        // Volver a cargar los estados
        const updatedEstados = await getEstadoAlbaran();
        setEstados(updatedEstados);
      }
    } catch (error) {
      console.error("Error al actualizar estado:", error);
      setSnackbarMessage("Error al actualizar el estado");
      setShowSnackbar(true);
    }
  };

  const handleOpenDialog = () => {
    setOpenDialogEst(true);
  };

  const handleCloseDialog = () => {
    setOpenDialogEst(false);
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setSelectedEstado((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  return (
    <>
      <Titulo name="Estados" type="estado" />
      <Box className="filterBox">
        <FilterBox
          origen="Estados"
          filters={filters}
          setFilters={setFilters}
          onFilterChange={handleFilterChange}
          data={estados}
        />
      </Box>
      <Box className="boxContainer">
        <Box className="boxTablas">
          <TableContainer component={Paper} className="tableContainer">
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell onClick={handleSort} className="tableHeaderCell">
                    Estados de Albáran
                    {sortDirection === "asc" ? (
                      <ArrowDropUpIcon className="headerTableIcons" />
                    ) : (
                      <ArrowDropDownIcon className="headerTableIcons" />
                    )}
                  </TableCell>
                  <TableCell align="center" className="tableHeaderCell">
                    Editar
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {datosFiltrados.map((estado) => (
                  <TableRow key={estado.est_alb_id} className="tableCeldas">
                    {Object.keys(estado).map(
                      (key, index) =>
                        key !== "est_alb_id" && (
                          <TableCell key={index}> {estado[key]}</TableCell>
                        )
                    )}
                    <TableCell align="center">
                      <IconButton
                        onClick={() => handleEdit(estado)}
                        variant="outlined"
                        color="primary"
                        sx={{ fontSize: "1.3em" }}
                      >
                        <FaEdit />
                      </IconButton>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Box>
        <Box className="boxAcciones">
          <Typography className="headerAction" variant="h6">
            Acciones Estados Albarán
          </Typography>
          <Button
            variant="contained"
            className="btnAcciones"
            onClick={handleOpenDialog}
            endIcon={<AddIcon />}
          >
            Crear Estado Albarán
          </Button>
        </Box>

        <Snackbar
          open={showSnackbar}
          autoHideDuration={6000}
          onClose={() => setShowSnackbar(false)}
          message={snackbarMessage}
          sx={{ bottom: 100 }}
        />
        {/* Diálogo para editar estado */}
        <Dialog
          open={openDialog}
          onClose={() => setOpenDialog(false)}
          maxWidth="md"
          fullWidth
        >
          <DialogTitle sx={{ marginBottom: "20px" }}>Editar Estado</DialogTitle>
          <DialogContent style={{ paddingTop: "10px" }}>
            <TextField
              label="Nombre del Estado"
              style={{ marginBottom: "10px", paddingTop: "0" }}
              variant="outlined"
              fullWidth
              name="est_alb_nombre"
              value={selectedEstado.est_alb_nombre}
              onChange={handleInputChange}
            />
          </DialogContent>
          <DialogActions>
            <Button onClick={() => setOpenDialog(false)} color="error">
              Cancelar
            </Button>
            <Button onClick={handleSubmitEdit} color="primary">
              Guardar Cambios
            </Button>
          </DialogActions>
        </Dialog>

        {/* Snackbar para mensajes de éxito/error */}
        <CrearEstados
          openDialog={openDialogEst}
          onClose={handleCloseDialog}
          setSnackbarMessage={setSnackbarMessage}
          setOpenSnackbar={setShowSnackbar}
          setEstados={setEstados}
        />
      </Box>
    </>
  );
};

export default UpdateEstados;
