import React, { useState } from "react";
import {
  TextField,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
} from "@mui/material";
import { Grid } from "@mui/material";
import { addProveedor, getProveedores } from "../service/apiProveedores";
import Modal from "../modal/modal"; // Usamos el mismo modal para mostrar el mensaje

const CrearProveedor = ({
  openDialog,
  onClose,
  setSnackbarMessage,
  setOpenSnackbar,
  setProveedores,
}) => {
  const [showModal, setShowModal] = useState(false);
  const [modalMessage, setModalMessage] = useState("");
  const [formData, setFormData] = useState({
    prov_nombre: "",
    prov_direccion: "",
    prov_codigo_postal: 0,
    prov_poblacion: "",
    prov_provincia: "",
    prov_nif: "",
    prov_telefono: 0,
    prov_email: "",
    prov_cta_bancaria: "",
    prov_num_tarjeta_ecopay: "",
    prov_total_op_anual: 0,
    prov_matricula: "",
  });
  const formFields = [
    {
      label: "Nombre del Proveedor",
      name: "prov_nombre",
      type: "text",
      required: true,
    },
    {
      label: "Dirección",
      name: "prov_direccion",
      type: "text",
      required: true,
    },
    {
      label: "Código Postal",
      name: "prov_codigo_postal",
      type: "number",
      required: true,
      min: 0,
    },
    {
      label: "Población",
      name: "prov_poblacion",
      type: "text",
      required: true,
    },
    {
      label: "Provincia",
      name: "prov_provincia",
      type: "text",
      required: true,
    },
    { label: "NIF", name: "prov_nif", type: "text", required: true },
    {
      label: "Teléfono",
      name: "prov_telefono",
      type: "number",
      required: true,
      min: 0,
    },
    { label: "Email", name: "prov_email", type: "email", required: true },
    { label: "Cuenta Bancaria", name: "prov_cta_bancaria", type: "text" },
    {
      label: "Número Tarjeta Ecopay",
      name: "prov_num_tarjeta_ecopay",
      type: "number",
      min: 0,
    },
    {
      label: "Total Operaciones Anuales",
      name: "prov_total_op_anual",
      type: "number",
      min: 0,
    },
    { label: "Matrícula", name: "prov_matricula", type: "text" },
  ];

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    //Pendiente agregar validacion de campos para dar mensaje sobre los campos obligatorios

    try {
      const response = await addProveedor(formData);
      if (response) {
        setSnackbarMessage(
          `Proveedor ${formData.prov_nombre} creado con éxito`
        );
        setOpenSnackbar(true);
        const updateProveedores = await getProveedores();
        setProveedores(updateProveedores);
        onClose();
      } else {
        setSnackbarMessage("Hubo un error al crear el proveedor");
        setOpenSnackbar(true);
      }
    } catch (error) {
      console.error("Error al crear el proveedor:", error);
      setSnackbarMessage("Hubo un error al crear el proveedor");
      setOpenSnackbar(true);
    }
  };

  return (
    <>
      <Dialog open={openDialog} onClose={onClose} maxWidth="sm" fullWidth>
        <DialogTitle variant="h4" gutterBottom className="headerDialog">
          Crear Proveedor
        </DialogTitle>
        <DialogContent>
          <form onSubmit={handleSubmit}>
            <Grid container spacing={2}>
              {formFields.map(({ label, name, type, required, min }) => (
                <Grid item xs={12} key={name}>
                  <TextField
                    fullWidth
                    label={label}
                    variant="outlined"
                    name={name}
                    value={formData[name] || ""}
                    onChange={handleChange}
                    required={required}
                    type={type}
                    inputProps={min !== undefined ? { min } : undefined}
                  />
                </Grid>
              ))}
              <Grid item xs={12}>
                <Button
                  variant="contained"
                  color="primary"
                  fullWidth
                  type="submit"
                >
                  Crear Proveedor
                </Button>
              </Grid>
            </Grid>
          </form>
        </DialogContent>
        <DialogActions>
          <Button onClick={onClose} color="secondary">
            Cancelar
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default CrearProveedor;
