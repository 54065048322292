import axios from "axios";
import API_URL from "../config";

export const getPrecioResiduo = async () => {
  try {
    const response = await axios.get(
      `${API_URL.Api}/precioResiduo/getPrecioResiduo`
    );
    return response.data;
  } catch (error) {
    console.error("Error obteniendo tabla precios residuos", error);
    return [];
  }
};
export const updatePrecioResiduo = async (datosActualizados) => {
  console.log("servicio update", datosActualizados);
};
