import React from "react";
import { AppBar, Toolbar, Button, Menu, MenuItem, Box } from "@mui/material";
import { useNavigate, useLocation } from "react-router-dom";
import "../style/navBar.css";

export default function Navbar() {
  const [anchorElAlb, setAnchorElAlb] = React.useState(null);
  const navigate = useNavigate();
  const location = useLocation();
  const handleMenuOpen = (setter) => (event) => {
    setter(event.currentTarget);
  };
  const handleMenuClose = (setter) => () => {
    setter(null);
  };
  const styleHead = {
    fontSize: "1.1rem",
    textTransform: "capitalize",
  };
  const isActive = (path) => location.pathname === path;
  return (
    <AppBar position="static" sx={{ backgroundColor: "#1abd9c" }}>
      <Toolbar className="toolbarMenu">
        <Box display="flex" gap={4} alignItems="center">
          {/* Menú Albaranes */}
          <Button
            color="inherit"
            onClick={handleMenuOpen(setAnchorElAlb)}
            sx={styleHead}
            className={`menu-button ${isActive("/albaranes") ? "active" : ""}`}
          >
            Albaranes
          </Button>
          <Menu
            anchorEl={anchorElAlb}
            open={Boolean(anchorElAlb)}
            onClose={handleMenuClose(setAnchorElAlb)}
          >
            <MenuItem
              onClick={() => {
                handleMenuClose(setAnchorElAlb)();
                navigate("/crear-albaran");
              }}
              className={`menu-button ${
                isActive("/crear-albaran") ? "active" : ""
              }`}
            >
              Crear Albaran
            </MenuItem>
            <MenuItem
              onClick={() => {
                handleMenuClose(setAnchorElAlb);
                navigate("/editar-albaran");
              }}
              className={`menu-button ${
                isActive("/editar-albaran") ? "active" : ""
              }`}
            >
              Editar Albaran
            </MenuItem>
          </Menu>

          {/* Menú Almacenes */}
          <Button
            color="inherit"
            sx={styleHead}
            onClick={() => navigate("/almacenes")}
            className={`menu-button ${isActive("/almacenes") ? "active" : ""}`}
          >
            Almacenes
          </Button>

          {/* Menú Estados */}
          <Button
            color="inherit"
            sx={styleHead}
            onClick={() => navigate("/estados-albaran")}
            className={`menu-button ${
              isActive("/estados-albaran") ? "active" : ""
            }`}
          >
            Estados
          </Button>

          {/* Menú Proveedores */}
          <Button
            color="inherit"
            sx={styleHead}
            onClick={() => navigate("/proveedores")}
            className={`menu-button ${
              isActive("/proveedores") ? "active" : ""
            }`}
          >
            Proveedores
          </Button>

          {/* Menú Residuos */}
          <Button
            color="inherit"
            sx={styleHead}
            onClick={() => navigate("/residuos")}
            className={`menu-button ${isActive("/residuos") ? "active" : ""}`}
          >
            Residuos
          </Button>

          <Button
            color="inherit"
            onClick={() => navigate("/precioResiduoAlmacen")}
            sx={styleHead}
            className={`menu-button ${
              isActive("/precioResiduoAlmacen") ? "active" : ""
            }`}
          >
            Precio Residuos
          </Button>
        </Box>
      </Toolbar>
    </AppBar>
  );
}
