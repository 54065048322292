import axios from "axios";
import API_URL from "../config";

export const addAlbaran = async (albaranData) => {
  try {
    const response = await axios.post(
      `${API_URL.Api}/albaran/addAlbaran`,
      albaranData
    );
    return response.data.insertId; // Devuelve la respuesta del servidor
  } catch (error) {
    console.error("Error al crear el albarán:", error);
    throw error; // Propaga el error para manejarlo en el componente
  }
};

export const addLinea = async (lineaData) => {
  try {
    const response = await axios.post(
      `${API_URL.Api}/lineaAlbaran/addLineaAlbaran`,
      lineaData
    );
    return response.data.insertId; // Devuelve la respuesta del servidor
  } catch (error) {
    console.error("Error al agregar una línea:", error);
    throw error; // Propaga el error para manejarlo en el componente
  }
};

export const getAlbaranes = async () => {
  try {
    const response = await axios.get(`${API_URL.Api}/albaran/getAlbaranes`);
    return response.data;
  } catch (error) {
    console.error("Error obteniendo albaranes", error);
    return [];
  }
};

export const getLineasAlbaran = async (id) => {
  try {
    const response = await axios.get(
      `${API_URL.Api}/albaran/getLineasAlbaran/${id}`,
      {
        headers: {
          "Content-Type": "application/json",
        },
      }
    );
    return response.data;
  } catch (error) {
    console.error("Error al ", id, error);
    return null;
  }
};

export const updateAlbaran = async (id, albaranData) => {
  if (typeof albaranData != "object") {
    console.error("El albaranData debe ser un objeto", albaranData);
    return null;
  }
  try {
    const response = await axios.put(
      `${API_URL.Api}/albaran/updateAlbaran/${id}`,
      albaranData,
      {
        headers: {
          "Content-Type": "application/json",
        },
      }
    );
    return response.status;
  } catch (error) {
    console.error("Error al actualizar el albaran", error);
    return null;
  }
};
export const updateLineasAlbaran = async (idAlbaran, lineasActualizadas) => {
  if (!Array.isArray(lineasActualizadas)) {
    console.error(
      "Los datos de las líneas deben ser un array",
      lineasActualizadas
    );
    return null;
  }

  try {
    const response = await axios.put(
      `${API_URL.Api}/albaran/updateLineasAlbaran/${idAlbaran}`,
      lineasActualizadas, // 🔹 Se envía el array directamente
      {
        headers: {
          "Content-Type": "application/json",
        },
      }
    );
    return response;
  } catch (error) {
    console.error("Error al actualizar las líneas del albarán", error);
    return null;
  }
};

export const getTotalAlbaran = async (id) => {
  try {
    const response = axios.get(`${API_URL.Api}/albaran/getTotalAlbaran/${id}`, {
      headers: {
        "Content-Type": "application/json",
      },
    });
    return response;
  } catch (error) {
    console.error("Error al consultar el total del albarán: ", id);
    return null;
  }
};
