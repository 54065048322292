//const Api = 'http://localhost:8081/api';
const Api = "https://middleware.urbieta.eu/api";
// const Api = process.env.REACT_APP_API_URL;
const filtrosProveedor = {
  Nombre: "",
  NIF: "",
  Provincia: "",
};

const filtrosAlmacen = {
  Nombre: "",
};

const filtrosEstados = {
  Nombre: "",
};
const filtrosRsiduos = {
  Nombre: "",
  Ler: "",
};

const filtrosAlbaran = {
  Proveedor: "",
};

export default {
  Api,
  filtrosProveedor,
  filtrosAlmacen,
  filtrosEstados,
  filtrosRsiduos,
  filtrosAlbaran,
};
