import React, { useState, useEffect } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Button,
  Paper,
  Snackbar,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
  Box,
  Typography,
  IconButton,
} from "@mui/material";
import { getResiduos, updateResiduos } from "../service/apiResiduos";
import { FaEdit } from "react-icons/fa";
import CrearResiduos from "./createResiduos";
import ImportarResiduos from "../componentsImport/importResiduos";
import "../style/cajas.css";
import config from "../config";
import FilterBox from "../components/filterBox";
import ArrowDropUpIcon from "@mui/icons-material/ArrowDropUp";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import AddIcon from "@mui/icons-material/Add";
import GetAppIcon from "@mui/icons-material/GetApp";
import UploadIcon from "@mui/icons-material/Upload";
import * as XLSX from "xlsx";
import Titulo from "../components/titulos";

const UpdateResiduos = ({ datos }) => {
  const [residuos, setResiduos] = useState([]);
  const [showSnackbar, setShowSnackbar] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [openDialog, setOpenDialog] = useState(false);
  const [openDialogRes, setOpenDialogRes] = useState(false);
  const [selectResiduo, setSelectedResiduo] = useState({
    res_id: 0,
    res_ler: "",
    res_nombre: "",
    res_cod_interno: "",
    res_unidad_medida: "",
    res_iva: 0,
  });
  const [openDialogImport, setOpenDialogImport] = useState(false);
  const [loading, setLoading] = useState(true);
  const [filters, setFilters] = useState(config.filtrosRsiduos);
  const [datosFiltrados, setDatosFiltrados] = useState(datos || []);
  const [sortDirection, setSortDirection] = useState("asc");
  const [sortDirectionLer, setSortDirectionLer] = useState("asc");
  const celdas = [
    "Código Ler",
    "Nombre",
    "Código Interno",
    "Unidad de Medida",
    "IVA",
    "Editar",
  ];

  useEffect(() => {
    const fetchResiduos = async () => {
      try {
        const response = await getResiduos();
        setResiduos(response);
      } catch (error) {
        console.error("Error al obtener los residuos", error);
      } finally {
        setLoading(false);
      }
    };
    fetchResiduos();
  }, []);
  useEffect(() => {
    setDatosFiltrados(filtrarResiduos(residuos));
  }, [filters, residuos]);

  const handleSort = () => {
    const sortedResiduos = [...residuos].sort((a, b) => {
      const nomA = a.res_nombre.trim().toLowerCase();
      const nomB = b.res_nombre.trim().toLowerCase();

      if (nomA < nomB) return sortDirection === "asc" ? -1 : 1;
      if (nomA > nomB) return sortDirection === "asc" ? 1 : -1;
      return 0;
    });
    setResiduos(sortedResiduos);
    setSortDirection(sortDirection === "asc" ? "desc" : "asc");
  };
  const handleSortLer = () => {
    const sortedResiduos = [...residuos].sort((a, b) => {
      const nomA = a.res_ler.trim().toLowerCase();
      const nomB = b.res_ler.trim().toLowerCase();

      if (nomA < nomB) return sortDirectionLer === "asc" ? -1 : 1;
      if (nomA > nomB) return sortDirectionLer === "asc" ? 1 : -1;
      return 0;
    });
    setResiduos(sortedResiduos);
    setSortDirectionLer(sortDirectionLer === "asc" ? "desc" : "asc");
  };
  const handleEdit = (residuo) => {
    setSelectedResiduo(residuo);
    setOpenDialog(true);
  };
  const handleFilterChange = (name, value) => {
    setFilters((prevFilters) => ({
      ...prevFilters,
      [name]: value,
    }));
  };
  const filtrarResiduos = (residuos) => {
    return residuos.filter((residuo) => {
      return Object.entries(filters).every(([key, value]) => {
        if (!value || typeof value !== "string" || !value.trim()) return true;

        const field = residuo[`res_${key.toLowerCase()}`];
        return field?.toString().toLowerCase().includes(value.toLowerCase());
      });
    });
  };
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setSelectedResiduo((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };
  const handleSubmitEdit = async () => {
    try {
      const dataToSend = {
        res_ler: selectResiduo.res_ler,
        res_nombre: selectResiduo.res_nombre,
        res_cod_interno: selectResiduo.res_cod_interno,
        res_unidad_medida: selectResiduo.res_unidad_medida,
        res_iva: selectResiduo.res_iva,
      };

      const response = await updateResiduos(selectResiduo.res_id, dataToSend);
      if (response) {
        setSnackbarMessage("Residuo actualizado correctamente");
        setShowSnackbar(true);
        setOpenDialog(false);
        // Volver a cargar los estados
        const updateResiduos = await getResiduos();
        setResiduos(updateResiduos);
      }
    } catch (error) {
      console.error("Error al actualizar residuo:", error);
      setSnackbarMessage("Error al actualizar el residuo");
      setShowSnackbar(true);
    }
  };
  const handleOpenDialog = () => {
    setOpenDialogRes(true);
  };
  const handleCloseDialog = () => {
    setOpenDialogRes(false);
  };
  const handleOpenDialogImport = () => {
    setOpenDialogImport(!openDialogImport);
  };
  const handleDescargarExcel = () => {
    const datos = [
      {
        ler: "123456",
        nombre: "Nombre del Residuo",
        codigo_interno: "567890",
        unidad_medida: "Kg",
        iva: "12",
        maestro: "54",
        fianzas: "57",
      },
      {
        ler: "654321",
        nombre: "Nombre del Residuo 2",
        codigo_interno: "098765",
        unidad_medida: "Kg",
        iva: "10",
        maestro: "47",
        fianzas: "50",
      },
    ];

    const worksheet = XLSX.utils.json_to_sheet(datos);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "Datos");

    const excelBuffer = XLSX.write(workbook, {
      bookType: "xlsx",
      type: "array",
    });

    const blob = new Blob([excelBuffer], {
      type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
    });

    const url = URL.createObjectURL(blob);
    const link = document.createElement("a");
    link.href = url;
    link.download = "estructuraCargaResiduos.xlsx";
    link.click();

    URL.revokeObjectURL(url);
  };

  return (
    <>
      <Titulo type="residuo" name="Residuos" />
      <Box className="filterBox">
        <FilterBox
          origen="Residuos"
          filters={filters}
          setFilters={setFilters}
          onFilterChange={handleFilterChange}
          data={residuos}
        />
      </Box>
      <Box className="boxContainer">
        <Box className="boxTablas">
          <TableContainer component={Paper} className="tableContainer">
            <Table>
              <TableHead>
                <TableRow>
                  {celdas.map((header) => (
                    <TableCell
                      className="tableHeaderCell"
                      key={header}
                      onClick={() => {
                        if (header === "Nombre") {
                          handleSort();
                        } else if (header === "Código Ler") {
                          handleSortLer();
                        }
                      }}
                    >
                      {header}

                      {header === "Nombre" &&
                        (sortDirection === "asc" ? (
                          <ArrowDropDownIcon className="headerTableIcons" />
                        ) : (
                          <ArrowDropUpIcon className="headerTableIcons" />
                        ))}
                      {header === "Código Ler" &&
                        (sortDirectionLer === "asc" ? (
                          <ArrowDropDownIcon className="headerTableIcons" />
                        ) : (
                          <ArrowDropUpIcon className="headerTableIcons" />
                        ))}
                    </TableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {loading ? (
                  <TableRow>
                    <TableCell colSpan={celdas.length} align="center">
                      Cargando Datos de los Resiudos
                    </TableCell>
                  </TableRow>
                ) : (
                  datosFiltrados.map((residuo) => (
                    <TableRow key={residuo.res_id} className="tableCeldas">
                      {Object.keys(residuo).map(
                        (key, index) =>
                          key !== "res_id" && (
                            <TableCell className="tableContenido" key={index}>
                              {residuo[key]}
                            </TableCell>
                          )
                      )}
                      <TableCell>
                        <IconButton
                          onClick={() => handleEdit(residuo)}
                          variant="outlined"
                          color="primary"
                          sx={{ fontSize: "1.3em" }}
                        >
                          <FaEdit />
                        </IconButton>
                      </TableCell>
                    </TableRow>
                  ))
                )}
              </TableBody>
            </Table>
          </TableContainer>
        </Box>
        <Box className="boxAcciones">
          <Typography className="headerAction" variant="h6">
            Acciones de Residuos
          </Typography>
          <Button
            variant="contained"
            className="btnAcciones"
            onClick={handleOpenDialog}
            endIcon={<AddIcon />}
          >
            Crear Residuos
          </Button>
          <Button
            variant="contained"
            className="btnAcciones"
            onClick={handleOpenDialogImport}
            endIcon={<UploadIcon />}
          >
            Importar Residuos
          </Button>
          <Button
            className="btnAcciones"
            variant="contained"
            endIcon={<GetAppIcon />}
            onClick={handleDescargarExcel}
          >
            Descargar Plantilla
          </Button>
        </Box>
      </Box>
      <Snackbar
        open={showSnackbar}
        autoHideDuration={6000}
        onClose={() => setShowSnackbar(false)}
        message={snackbarMessage}
      />
      {/* Diálogo para editar residuo */}
      <Dialog
        open={openDialog}
        onClose={() => setOpenDialog(false)}
        maxWidth="md"
        fullWidth
      >
        <DialogTitle sx={{ marginBottom: "20px" }}>Editar Residuo</DialogTitle>
        <DialogContent style={{ paddingTop: "10px" }}>
          <TextField
            label="Nombre"
            style={{ marginBottom: "10px", paddingTop: "0" }}
            variant="outlined"
            fullWidth
            name="res_nombre"
            value={selectResiduo.res_nombre}
            onChange={handleInputChange}
          />
          <TextField
            label="Código Ler"
            style={{ marginBottom: "10px", paddingTop: "0" }}
            variant="outlined"
            fullWidth
            name="res_ler"
            value={selectResiduo.res_ler}
            onChange={handleInputChange}
          />
          <TextField
            label="Código interno"
            style={{ marginBottom: "10px", paddingTop: "0" }}
            variant="outlined"
            fullWidth
            name="res_cod_interno"
            value={selectResiduo.res_cod_interno}
            onChange={handleInputChange}
          />

          <TextField
            label="Unidad de Medida"
            style={{ marginBottom: "10px", paddingTop: "0" }}
            variant="outlined"
            fullWidth
            name="res_unidad_medida"
            value={selectResiduo.res_unidad_medida}
            onChange={handleInputChange}
          />
          <TextField
            label="IVA"
            style={{ marginBottom: "10px", paddingTop: "0" }}
            variant="outlined"
            fullWidth
            name="res_iva"
            value={selectResiduo.res_iva}
            onChange={handleInputChange}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpenDialog(false)} color="error">
            Cancelar{" "}
          </Button>
          <Button onClick={handleSubmitEdit} color="primary">
            Guardar Cambios{" "}
          </Button>
        </DialogActions>
      </Dialog>
      <CrearResiduos
        openDialog={openDialogRes}
        onClose={handleCloseDialog}
        setSnackbarMessage={setSnackbarMessage}
        setOpenSnackbar={setShowSnackbar}
        setResiduos={setResiduos}
      />

      <ImportarResiduos
        openDialog={openDialogImport}
        onClose={handleOpenDialogImport}
      />
    </>
  );
};

export default UpdateResiduos;
