import React, { useState } from "react";
import {
  TextField,
  Button,
  Box,
  Typography,
  Grid,
  Container,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
} from "@mui/material";
import { addResiduo, getResiduos } from "../service/apiResiduos";
import Modal from "../modal/modal";

const CrearResiduos = ({
  openDialog,
  onClose,
  setSnackbarMessage,
  setOpenSnackbar,
  setResiduos,
}) => {
  const [showModal, setShowModal] = useState(false);
  const [modalMessage, setModalMessage] = useState("");
  const [formData, setFormData] = useState({
    res_ler: "",
    res_nombre: "",
    res_cod_interno: "",
    res_unidad_medida: "",
    res_iva: 0,
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await addResiduo(formData);
      if (response) {
        setSnackbarMessage(`Residuo ${formData.prov_nombre} creado con éxito`);
        setOpenSnackbar(true);
        const updateResiduos = await getResiduos();
        setResiduos(updateResiduos);
        onClose();
      } else {
        setSnackbarMessage("Hubo un error al crear el reiduo");
        setOpenSnackbar(true);
      }
    } catch (error) {
      console.error("Error al crear el residuo:", error);
      setSnackbarMessage("Hubo un error al crear el residuo");
      setOpenSnackbar(true);
    }
  };

  return (
    <>
      <Dialog open={openDialog} onClose={onClose} maxWidth="sm" fullWidth>
        <DialogTitle> Crear Residuos </DialogTitle>
        <DialogContent>
          <Box>
            <form onSubmit={handleSubmit}>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <TextField
                    fullWidth
                    label="Código LER"
                    variant="outlined"
                    name="res_ler"
                    value={formData.res_ler}
                    onChange={handleChange}
                    required
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    fullWidth
                    label="Nombre del Residuo"
                    variant="outlined"
                    name="res_nombre"
                    value={formData.res_nombre}
                    onChange={handleChange}
                    required
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    fullWidth
                    label="Código Interno"
                    variant="outlined"
                    name="res_cod_interno"
                    value={formData.res_cod_interno}
                    onChange={handleChange}
                    required
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    fullWidth
                    label="Unidad de Medida"
                    variant="outlined"
                    name="res_unidad_medida"
                    value={formData.res_unidad_medida}
                    onChange={handleChange}
                    required
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    fullWidth
                    label="IVA (%)"
                    variant="outlined"
                    name="res_iva"
                    type="number"
                    value={formData.res_iva}
                    onChange={handleChange}
                    required
                    inputProps={{ min: 0 }}
                  />
                </Grid>
                <Grid item xs={12}>
                  <Button
                    variant="contained"
                    color="primary"
                    fullWidth
                    type="submit"
                  >
                    Crear Residuo
                  </Button>
                  {showModal && (
                    <Modal
                      message={modalMessage}
                      onClose={() => setShowModal(false)}
                    />
                  )}
                </Grid>
              </Grid>
            </form>
          </Box>
        </DialogContent>
        <DialogActions>
          <Button onClick={onClose} color="secondary">
            Cancelar
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default CrearResiduos;
